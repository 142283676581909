const extractBuildSummaryXML = (data) => {
  const stats = data.testingResults['testng-results'];
  return {
    passed: stats.passed || 0,
    failed: stats.failed || 0,
    ignored: 0,
    skipped: stats.skipped || 0,
    total: stats.passed + stats.failed + 0 + stats.skipped,
  };
};

const extractBuildSummaryHTML = (data) => {
  const stats = data.testingResults;
  return {
    passed: stats.passed || 0,
    failed: stats.failed || 0,
    skipped: stats.skipped || 0,
    retried: 0,
    total: stats.passed + stats.failed + 0 + stats.skipped,
  };
};

const extractBuildSummaryPlaywright = (data) => {
  const { stats } = data.reportResult;
  return {
    passed: stats.expected || 0,
    failed: stats.unexpected || 0,
    flaky: 0,
    skipped: stats.skipped || 0,
    total: stats.expected + stats.unexpected + 0 + stats.skipped,
  };
};
const extractBuildSummaryCypress = (data) => {
  const { stats } = data.reportResult.dataRow;
  return {
    passed: stats.passes || 0,
    failed: stats.failures || 0,
    pending: 0,
    skipped: stats.skipped || 0,
    total: stats.passes + stats.failures + 0 + stats.skipped,
  };
};

export {
  extractBuildSummaryXML,
  extractBuildSummaryCypress,
  extractBuildSummaryPlaywright,
  extractBuildSummaryHTML,
};
