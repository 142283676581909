import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Select, MenuItem, TextField, Button,
} from '@mui/material';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import SearchIcon from '@mui/icons-material/Search';

const VirtualizedDropdown = ({
  data,
  value,
  onChange,
  itemHeight = 50,
  placeholder = 'No Data Found',
  renderItem,
  label = '',
  isCurved,
  isSearchable,
  onFooterButtonClick,
  footerButtonLabel = 'Create New Item',
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    if (search.trim() === '') {
      setFilteredData(data);
    } else {
      setFilteredData(
        data?.filter((item) => {
          if (typeof item.label === 'string') return item.label.toLowerCase().includes(search.toLowerCase());
          return item.searchableLabel.toLowerCase().includes(search.toLowerCase());
        }),
      );
    }
  }, [search, data]);

  const handleSelect = (selectedValue) => {
    onChange({ target: { value: selectedValue } });
    setOpen(false);
  };

  const calculatedHeight = Math.min(filteredData?.length * itemHeight, 500);
  const listHeight = footerButtonLabel ? Math.max(calculatedHeight, 500 - 60) : calculatedHeight;

  if (!data || data?.length === 0) {
    return (
      <Select
        value=""
        label={label || ''}
        disabled
        sx={{ borderRadius: isCurved ? '20px' : '0px' }}
        {...rest}
      >
        <MenuItem disabled style={{ color: '#999' }}>
          {placeholder}
        </MenuItem>
      </Select>
    );
  }

  return (
    <Select
      value={value || ''}
      open={open}
      label={label || ''}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 500,
            overflow: 'auto',
          },
        },
      }}
      sx={{
        borderRadius: isCurved ? '20px' : '4px',
      }}
      renderValue={(selected) => {
        const selectedItem = filteredData && filteredData.find((item) => item.value === selected);
        return selectedItem ? selectedItem.label : `Select ${label}`;
      }}
      {...rest}
    >
      <div
        style={{
          height: filteredData?.length > 5 ? listHeight : 'auto',
          maxHeight: '500px',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        {isSearchable && filteredData?.length > 5 && (
          <TextField
            size="small"
            fullWidth
            value={search}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search..."
            sx={{
              padding: 1,
              position: 'sticky',
              top: 0,
              background: '#fff',
              zIndex: 1,
            }}
            InputProps={{
              style: {
                borderRadius: isCurved ? '20px' : '4px',
              },
              endAdornment: (
                <SearchIcon style={{ color: '#999', cursor: 'pointer' }} />
              ),
            }}
          />
        )}
        {filteredData && filteredData?.length ? (
          <>
            {filteredData.length <= 5 ? (
              filteredData.map((item) => (
                <MenuItem
                  key={item.value}
                  value={item.value}
                  onClick={() => handleSelect(item.value)}
                >
                  {renderItem(item)}
                </MenuItem>
              ))
            ) : (
              <div style={{ height: listHeight }}>
                <AutoSizer>
                  {({ height, width }) => (
                    <FixedSizeList
                      height={height}
                      width={width}
                      itemCount={filteredData.length}
                      itemSize={itemHeight}
                      itemData={filteredData}
                    >
                      {({ index, style }) => (
                        <MenuItem
                          key={filteredData[index].value}
                          value={filteredData[index].value}
                          style={style}
                          onClick={() => handleSelect(filteredData[index].value)}
                        >
                          {renderItem(filteredData[index])}
                        </MenuItem>
                      )}
                    </FixedSizeList>
                  )}
                </AutoSizer>
              </div>
            )}
          </>
        ) : (
          <MenuItem disabled style={{ color: '#999' }}>
            No Data Found
          </MenuItem>
        )}
        {footerButtonLabel !== '' && (
          <div
            style={{
              position: 'sticky',
              bottom: 0,
              background: '#fff',
              padding: '8px',
              borderTop: '1px solid #ddd',
              textAlign: 'center',
            }}
          >
            <Button
              variant="text"
              color="primary"
              fullWidth
              onClick={onFooterButtonClick}
            >
              {footerButtonLabel}
            </Button>
          </div>
        )}
      </div>
    </Select>
  );
};

VirtualizedDropdown.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
      searchableLabel: PropTypes.string,
    }),
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  itemHeight: PropTypes.number,
  placeholder: PropTypes.string,
  renderItem: PropTypes.func.isRequired,
  label: PropTypes.string,
  isCurved: PropTypes.bool,
  isSearchable: PropTypes.bool,
  onFooterButtonClick: PropTypes.func,
  footerButtonLabel: PropTypes.string,
};

VirtualizedDropdown.defaultProps = {
  value: '',
  itemHeight: 30,
  placeholder: 'No Data Found',
  label: '',
  isCurved: false,
  isSearchable: false,
  onFooterButtonClick: () => {},
  footerButtonLabel: '',
};

export default VirtualizedDropdown;
