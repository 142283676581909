import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Typography, Button, Menu, Chip, Tabs, Tab, TextField,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { Cancel } from '@mui/icons-material';
import { calculateDateRange } from './dashboard/utils';
import { dateRangeStyles } from './header/style';

const DateRangeFilter = ({
  labels,
  initialDateRange,
  onApply,
}) => {
  const classes = dateRangeStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTab, setSelectedTab] = useState();
  const [startValue, setStartValue] = useState(initialDateRange?.startDate || null);
  const [endValue, setEndValue] = useState(initialDateRange?.endDate || null);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    if (labels[newValue] === 'Custom') {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
      const calculatedRange = calculateDateRange(labels[newValue]);
      onApply(calculatedRange);
    }
  };

  const handleMenuClose = (apply) => {
    setAnchorEl(null);
    if (apply) {
      const newDateRange = {
        startDate: startValue?.startOf('day').format('YYYY-MM-DD'),
        endDate: endValue?.endOf('day').format('YYYY-MM-DD'),
      };
      onApply(newDateRange);
    }
  };

  return (
    <Box
      sx={{ display: 'flex', alignItems: 'left' }}
    >
      <Tabs
        value={selectedTab}
        indicatorColor="none"
        textColor="primary"
        alignItems="center"
        sx={{
          '& .MuiTabs-flexContainer': { alignItems: 'center' },
        }}
      >
        {labels.map((item) => (
          <Tab
            key={item}
            label={item}
            className={`${classes.tab} ${labels[selectedTab] === item ? classes.balloon : ''}`}
            onClick={(event) => {
              if (item === 'Custom') {
                handleTabChange(event, labels.indexOf(item));
              } else {
                handleTabChange(event, labels.indexOf(item));
              }
            }}
          />
        ))}
      </Tabs>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleMenuClose(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        PaperProps={{ style: { padding: '16px' } }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box p={2}>
            <Typography variant="h6" gutterBottom>Select Date Range</Typography>
            <DemoContainer components={['DatePicker']}>
              <Box mb={2}>
                <DatePicker
                  label="Start Date"
                  disableFuture
                  value={startValue ? dayjs(startValue) : null}
                  onChange={setStartValue}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Box>
              <DatePicker
                label="End Date"
                disableFuture
                value={endValue ? dayjs(endValue) : null}
                onChange={setEndValue}
                minDate={startValue}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </DemoContainer>
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button variant="contained" onClick={() => handleMenuClose(true)}>
                Apply
              </Button>
            </Box>
          </Box>
        </LocalizationProvider>
      </Menu>
      {initialDateRange.startDate && initialDateRange.endDate && <Chip
        variant="outlined"
        label={`${initialDateRange.startDate || 'Start'} / ${initialDateRange.endDate || 'End'}`}
        className={classes.chipLabel}
        onDelete={() => { setSelectedTab(); onApply(); }}
        deleteIcon={<Cancel style={{ color: '#fff' }} />}
      />}
    </Box>
  );
};

DateRangeFilter.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string),
  initialDateRange: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  onApply: PropTypes.func.isRequired,
};

DateRangeFilter.defaultProps = {
  labels: ['1D', '7D', '30D', '6M', '1Y', 'Custom'],
  initialDateRange: { startDate: null, endDate: null },
};

export default DateRangeFilter;
