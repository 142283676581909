import React, { useEffect, useState } from 'react';
import {
  Button,
  Menu,
  Avatar,
  Typography,
  Tooltip,
  Badge,
  TableContainer,
  Table,
  TableBody,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Grid,
} from '@mui/material';
import Icon from '@mui/material/Icon';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Link, useNavigate } from 'react-router-dom';
import DialogContent from '@mui/material/DialogContent';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { parseDate } from '../dashboard/utils';
import { avatarFields } from '../../utils/Header';
import {
  headerStyles,
  staticStyleObj,
  StyledTableCell,
  StyledTableRow,
  useStyles,
} from './style';
import { useCommonContext } from './context';
import { MENU_LIST, USER_MENU_LIST } from './menu';
import userService from '../../services/user.service';
import { UserPermission } from '../../utils/constants';
import notificationService from '../../services/notification.service';
import Logo from '../../assets/test-report-logo-dark.svg';
import route from '../../utils/route';

function Header() {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    setToggleHeader,
    user,
    setUser,
    notification,
    setNotification,
    setReportType,
    countNotify,
    setCounNotify,
  } = useCommonContext();
  const styles = headerStyles();

  const [, setMenus] = useState([]);
  // const [anchorEl, setAnchorEl] = useState(null);
  const [notifyEls, setNotifyEls] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [isRead, setIsRead] = useState(false);

  let stompClient = null;
  let privateStompClient = null;
  // const [seed, setSeed] = useState(1);

  const notificationsPerPage = 5;
  const [next, setNext] = useState(notificationsPerPage);

  const handleMoreNotifications = () => {
    setNext(next + 5);
  };

  const handleMenu = (event) => {
    setNotifyEls(event?.currentTarget);
  };

  const handleClosed = () => {
    setNotifyEls(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event?.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const accountOption = (item) => {
    if (item?.isLogout) {
      localStorage.clear();
      setUser(null);
    }
    navigate(item?.href);
  };

  const showMessage = (message) => {
    if (message?.id) {
      setNotification((prevMessages) => [message, ...prevMessages]);
      setCounNotify(message?.totalUnRead || 0);
    }
  };

  const token = localStorage.getItem('token');

  useEffect(() => {
    if (token) {
      const socket = new SockJS(`${window.location.origin}/qa-java-api/ws`);
      stompClient = Stomp.over(socket);
      stompClient.connect({ Authorization: `Bearer ${token}` }, () => {
        stompClient.subscribe('/all/messages', (result) => {
          showMessage(JSON.parse(result.body));
        });
      }, (error) => {
        console.error('Error connecting to WebSocket: ', error);
      });

      const privateSocket = new SockJS(`${window.location.origin}/qa-java-api/ws`);
      privateStompClient = Stomp.over(privateSocket);

      privateStompClient.connect({
        Authorization: `Bearer ${token}`,
      }, () => {
        privateStompClient.subscribe('/user/topic', (result) => {
          showMessage(JSON.parse(result.body));
        });
      }, (error) => {
        console.error('Error connecting to private WebSocket: ', error);
      });
    }

    // return () => {
    //   stompClient.disconnect();
    //   privateStompClient.disconnect();
    // };
  }, [token]);

  useEffect(() => {
    (async () => {
      try {
        // setTimeout(() => setSeed(Math.random()), 5000);
        const response = await notificationService.getNotification(next);
        if (response) {
          setNotification(response.content);
          setCounNotify(response?.content[0]?.totalUnRead || 0);
        }
      } catch (error) {
        console.log('Error - notificationUsers: ', error);
        // setTimeout(() => setSeed(Math.random()), 10000);
      }
    })();
  }, [next, isRead]);

  const markAsReadNotifications = async (item, markAsRead) => {
    try {
      const {
        id, read, fileType, reportId,
      } = item;
      await notificationService.updateNotification({
        id: [id],
        status: !read,
        readAll: markAsRead,
      });
      setIsRead(!isRead);
      handleClosed();
      if (item?.fileType && !markAsRead) {
        navigate(`/build/${fileType}/${reportId}`, { replace: true });
      }
    } catch (error) {
      console.log('Error - markAsReadNotifications: ', error);
    }
  };

  useEffect(() => {
    if (user?.role?.userPermissionsList.includes(UserPermission.GUEST_USER)) {
      setMenus(USER_MENU_LIST);
    } else {
      setMenus(MENU_LIST);
    }
  }, [user?.role?.roleId]);

  useEffect(async () => {
    const res = await userService.userInfo();
    setUser(res);
    setReportType(res?.framework[0]);
  }, []);

  const handleRedirection = () => {
    window.open('https://testreport.tawk.help/', '_blank');
  };

  return (
    // <Box className={classes.root}>
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.appBarInside}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={() => setToggleHeader((pre) => !pre)}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <div className={classes.logo}>
          <Link to={`/${route.ROUTE_HOME_PAGE}`}>
            <img src={Logo} alt="Logo" className={classes.logoImage} />
          </Link>
          <Typography variant="h6" color="inherit">
            testreport
          </Typography>
        </div>
        <div className={classes.actions}>
          <Tooltip title="account options" onClick={handleOpenUserMenu}>
            <IconButton
              color="warning"
              sx={staticStyleObj.avatarButton}
            >
              <AccountCircleIcon />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{
              ...staticStyleObj.avatarMenu,
            }}
            PaperProps={{
              sx: {
                boxShadow: '-4px 4px 0px #FFC72B,1px 1px 3px rgba(0, 0, 0, 0.2) ',
              },
            }}
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <List
              sx={{
                width: '100%',
                maxWidth: 360,
                bgcolor: 'background.paper',
                borderRadius: '8px',
                padding: '0',
              }}
            >
              <ListItem
                sx={{
                  flexDirection: 'column',
                  alignItems: 'center',
                  borderBottom: '1px solid #ebebeb',
                }}
              >
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      src={user?.image}
                      sx={{
                        border: '2px solid #FFC72B',
                        width: 50,
                        height: 50,
                      }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${user?.firstName} ${user?.lastName}`}
                    secondary={user?.role?.roleName}
                    primaryTypographyProps={{
                      fontWeight: 'bold',
                      color: '#333',
                      fontSize: '1.1rem',
                    }}
                    secondaryTypographyProps={{
                      fontSize: '0.875rem',
                      color: '#666',
                    }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    secondary={user?.email}
                    secondaryTypographyProps={{
                      fontSize: '0.875rem',
                      color: '#888',
                      textAlign: 'center',
                    }}
                  />
                </ListItem>
              </ListItem>
              {avatarFields?.map((setting) => (
                <ListItem
                  onClick={() => accountOption(setting)}
                  className={styles.avatarList}
                  sx={{
                    fontSize: '0.875rem',
                    '&:hover': {
                      backgroundColor: '#f0f0f0',
                      transform: 'scale(1.02)',
                      transition: 'transform 0.2s ease-in-out',
                    },
                  }}
                >
                  <ListItemButton
                    sx={{
                      '&:hover': {
                        background: 'transparent',
                      },
                    }}
                    onClick={handleCloseUserMenu}
                  >
                    <ListItemIcon sx={{
                      color: '#ffc72b',
                      fontSize: '1.2rem',
                    }}
                    >
                      <Icon>{setting.icon}</Icon>
                    </ListItemIcon>
                    <Box sx={{ alignItems: 'left' }}>
                      <ListItemText
                        primary={setting.text}
                        primaryTypographyProps={{
                          color: '#333',
                          fontWeight: 500,
                        }}
                      />
                    </Box>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Menu>
          <Button variant="contained" onClick={handleRedirection} className={classes.contactButton}>
            Need Help?
          </Button>
          <Tooltip title="Notifications" onClick={handleMenu}>
            <IconButton>
              <Badge max={Number.MAX_VALUE} badgeContent={countNotify} color="warning">
                <NotificationsIcon color="action" />
              </Badge>
            </IconButton>
          </Tooltip>
          <Menu
            keepMounted
            anchorEl={notifyEls}
            open={Boolean(notifyEls)}
            onClose={handleClosed}
            sx={staticStyleObj.avatarMenu}
            PaperProps={{
              sx: {
                boxShadow: '-4px 4px 0px #FFC72B,1px 1px 3px rgba(0, 0, 0, 0.2) ',
              },
            }}
            ele
            anchorOrigin={{
              horizontal: 'right',
            }}
            transformOrigin={{
              horizontal: 'right',
            }}
          >
            <DialogContent sx={{ padding: '0px 0', maxWidth: '500px' }}>
              <Grid display="flex" justifyContent="space-between">
                <Typography variant="h6" fontWeight="bold" style={{ marginLeft: '25px' }}>
                  Notifications
                </Typography>
                {notification?.length ? <Button
                  sx={{ color: '#bd9a2f' }}
                  variant="text"
                  font
                  onClick={() => markAsReadNotifications(notification[0], true)}
                >
                  Mark all as read
                </Button> : <></>}
              </Grid>
              <TableContainer>
                <Table>
                  <TableBody>
                    {notification?.length
                      ? notification?.map((item) => (
                        <StyledTableRow key={item.id}>
                          <StyledTableCell>
                            <Avatar
                              alt={item?.fromUser?.firstName}
                              src={item?.fromUser?.image}
                              className={styles.headerAvatar}
                              sx={{
                                border: '2px solid #FFC72B',
                                margin: 'auto',
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: 'left !important',
                          }}
                          >
                            <span>
                              <Button
                                sx={{
                                  p: 0,
                                  textTransform: 'capitalize',
                                  fontSize: '12px',
                                  justifyContent: 'flex-start',
                                  textAlign: 'start',
                                }}
                                size="small"
                                font
                                onClick={() => markAsReadNotifications(item, false)}
                              >
                                <Typography>
                                  {item?.message?.toLowerCase()}
                                </Typography>
                              </Button>
                            </span>
                            <span>
                              {parseDate(item?.createAt)}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell>
                            <div
                              style={{
                                width: '7px',
                                height: '7px',
                                borderRadius: '50%',
                                backgroundColor: `${item?.read ? 'blue' : 'red'}`,
                              }}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      )) : <StyledTableRow style={{ marginLeft: '25px' }}>
                        <StyledTableCell>
                          <Typography color="text.secondary">
                            No Notifications Found
                          </Typography>
                        </StyledTableCell>
                      </StyledTableRow>}
                  </TableBody>
                  {!!notification?.length && <Button
                    sx={{ marginLeft: 2 }}
                    onClick={handleMoreNotifications}
                  >
                    <Typography fontWeight="bold" sx={{ width: 'max-content' }}>
                      Load More...
                    </Typography>
                  </Button>}
                </Table>
              </TableContainer>
            </DialogContent>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
    // </Box>
  );
}
export default Header;
