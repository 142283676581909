/* eslint-disable react/no-array-index-key */
import React, { useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box, Typography, Button, Chip,
  Tooltip,
  ListItemText,
  Collapse,
  IconButton,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import stopwatchIcon from '../../../assets/stopwatch.png';
import DonutChart from '../../chart/DonutChart';
import { getDuration } from '../../Home/TestData';
import { TestStatus } from '../../../utils/constants';
import { useCommonContext } from '../../header/context';
import { toTitleCase } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F5F5F5',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  summaryHeaderBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingInline: theme.spacing(2),
    border: '1px solid #000000',
    margin: theme.spacing(2, 0),
    boxShadow: '-5px 5px 0px 0px #FFC72B',
    borderRadius: theme.spacing(1),
  },
  summaryTitleWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
  },
  summaryTitle: {
    fontWeight: 600,
    fontSize: '19px',
  },
  stepSummary: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  testSummmaryBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    border: '1px solid #E1E1E1',
    margin: theme.spacing(2, 0, 0),
    boxShadow: 'none',
    borderRadius: theme.spacing(1),
  },
  testHeaderMain: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  testWrapper: {
    width: '100%',
    padding: theme.spacing(1, 2),
    border: '1px solid #E1E1E1',
    margin: theme.spacing(2, 0, 0),
    boxShadow: 'none',
    borderRadius: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  stepHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
    margin: theme.spacing(0, 2),
    borderBottom: '1px solid #e0e0e0',
  },
  stepDetails: {
    padding: theme.spacing(2),
  },
  stepTitle: {
    display: 'flex',
    alignItems: 'baseline',
  },
  stepIcons: {
    display: 'flex',
    alignItems: 'center',
  },
  TAG_BUTTON: {
    borderRadius: '24px',
    color: '#fff',
    fontWeight: '500',
    padding: theme.spacing(0.5, 2),
    whiteSpace: 'nowrap',
  },
  TO_INVESTIGATE: {
    backgroundColor: '#000000',
    color: '#fff',
    '&:hover': {
      background: '#A6A6A6',
    },
  },
  PRODUCT_BUG: {
    backgroundColor: '#FF0000',
    '&:hover': {
      background: '#BF0000',
    },
  },
  AUTOMATION_BUG: {
    backgroundColor: '#FFC72B',
    '&:hover': {
      background: '#CC9A22',
    },
  },
  NO_DEFECT: {
    backgroundColor: '#1D6AE5',
    '&:hover': {
      background: '#154EB0',
    },
  },
  ENVIRONMENT_ISSUE: {
    backgroundColor: '#5FD726',
    '&:hover': {
      background: '#4AA61E',
    },
  },
  iconError: {
    fontSize: '12px',
    color: '#FF0000',
    marginRight: theme.spacing(1),
  },
  iconSuccess: {
    fontSize: '12px',
    color: '#5FD726',
    marginRight: theme.spacing(1),
  },
  browserInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  category: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(1.5, 2),
    margin: theme.spacing(1, 0),
    background: '#fff',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid #E7E7E7',
    '&:hover': {
      background: '#EFEFEF',
    },
  },
  chipSuccess: {
    color: '#fff',
    backgroundColor: '#5FD726',
    borderColor: '#5FD726',
    height: '25px',
  },
  chipFailure: {
    color: '#fff',
    backgroundColor: '#FF0000',
    borderColor: '#FF0000',
    height: '25px',
  },
  chipSkipped: {
    color: '#fff',
    backgroundColor: '#FFC72B',
    borderColor: '#FFC72B',
    height: '25px',
  },
  chipIgnored: {
    color: '#fff',
    backgroundColor: '#000000',
    borderColor: '#000000',
    height: '25px',
  },

  collapsedButton: {
    maxWidth: '30px',
    minWidth: '30px',
    height: '30px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'hidden',
    transition: 'max-width 0.5s ease-in-out',
    '&:hover': {
      maxWidth: ' 200px',
    },
  },
}));

const SeleniumXMLTestSteps = ({
  tags, testData, changeTest, openTest, project, id,
}) => {
  const [expandedItems, setExpandedItems] = useState({});
  const [tagFilter, setTagFilter] = useState({});

  const classes = useStyles();
  const { selectedfilter } = useCommonContext();

  const handleExpandClick = (itemName) => {
    setTagFilter({});
    setExpandedItems((prev) => ({
      [itemName]: !prev[itemName],
    }));
  };

  let listData = testData?.suite;
  if (listData?.test) {
    if (!Array.isArray(listData?.test)) {
      listData = [listData?.test];
    } else {
      listData = listData?.test;
    }
  }

  return (
    <>
      <Box className={classes.testHeaderMain}>
        <Box>
          <Typography className={classes.summaryTitle} variant="h6">{testData?.suite?.name}</Typography>
        </Box>
        <Box className={classes.stepSummary}>
          <Box className={classes.stepIcons}>
            <Box mx={2} display="flex" alignItems="center">
              <img src={stopwatchIcon} width={20} alt="Stopwatch" />
              <Typography variant="body2" color="textSecondary" ml={0.5}>{getDuration(testData?.suite['duration-ms'] || 0)}</Typography>
            </Box>
          </Box>
          <Box display="flex" gap={1}>
            <Tooltip title="Passed">
              <Chip
                variant="outlined"
                label={testData?.passed}
                className={classes.chipSuccess}
              />
            </Tooltip>
            <Tooltip title="Failed">
              <Chip
                variant="outlined"
                label={testData?.failed}
                className={classes.chipFailure}
              />
            </Tooltip>
            <Tooltip title="Ignored">
              <Chip
                variant="outlined"
                label={0}
                className={classes.chipIgnored}
              />
            </Tooltip>
            <Tooltip title="Skipped">
              <Chip
                variant="outlined"
                label={testData?.skipped}
                className={classes.chipSkipped}
              />
            </Tooltip>
          </Box>
          <Box>
            <DonutChart
              donutChartSuitesData={{
                passed: testData?.passed,
                failed: testData?.failed,
                skipped: testData?.skipped,
                ignored: 0,
              }}
            />
          </Box>
        </Box>
      </Box>
      {listData?.map((item) => {
        const methods = item?.class?.['test-method'] || [];
        const itemArray = Array.isArray(methods) ? methods : [methods];

        const hasException = useMemo(
          () => itemArray.some((itemA) => 'exception' in itemA),
          [itemArray],
        );

        const filteredMethods = useMemo(() => itemArray.filter((method) => {
          if (selectedfilter === TestStatus.PASS && !hasException) {
            return method.status === TestStatus.PASS;
          }
          if (selectedfilter === TestStatus.FAIL && hasException) {
            return method.status === TestStatus.FAIL;
          }
          return true;
        }), [itemArray, selectedfilter, hasException]);

        const failedTest = useMemo(() => {
          if (hasException && filteredMethods?.length) {
            return filteredMethods;
          }
          return filteredMethods?.filter((a) => a.status === TestStatus.FAIL) || [];
        }, [filteredMethods, hasException]);

        const itemTagData = tags[item.name] || [];
        const tagMap = useMemo(() => {
          const map = {};
          itemTagData.forEach((methodTags) => {
            Object.keys(methodTags).forEach((methodName) => {
              map[methodName] = methodTags[methodName]?.tagName;
            });
          });
          failedTest.forEach((test) => {
            if (!map[test.name] && test.status === TestStatus.FAIL) {
              map[test.name] = 'TO_INVESTIGATE';
            }
          });
          return map;
        }, [itemTagData, failedTest]);

        const uniqueTagNames = useMemo(
          () => [...new Set(Object.values(tagMap).filter(Boolean))],
          [tagMap],
        );

        const isFailed = !!hasException;
        const isExpanded = expandedItems[item?.name];

        const shouldRenderItem = useMemo(() => {
          if (selectedfilter === TestStatus.PASS && failedTest.length) {
            return false;
          }
          if (selectedfilter === TestStatus.FAIL && failedTest.length === 0) {
            return false;
          }
          return methods.length > 0;
        }, [selectedfilter, failedTest, methods.length]);

        return shouldRenderItem ? (
          <Box
            key={item.name}
            onClick={() => handleExpandClick(item.name)}
            className={classes.testWrapper}
          >
            <Box className={classes.stepHeader}>
              <Box className={classes.stepTitle}>
                <CircleIcon className={isFailed ? classes.iconError : classes.iconSuccess} />
                <Box>
                  <ListItemText
                    sx={{ flex: 1 }}
                    primary={item?.name}
                    secondary={item?.class?.name}
                  />
                </Box>
              </Box>
              <Box className={classes.stepSummary}>
                {isFailed && (
                <Box sx={{ display: 'flex', gap: 1 }}>
                  {uniqueTagNames.map((tagName) => (
                    <Button
                      key={tagName}
                      onClick={(e) => {
                        e.stopPropagation();
                        setExpandedItems(() => ({
                          [item.name]: true,
                        }));
                        setTagFilter({ [item.name]: tagName });
                      }}
                      className={`${classes.TAG_BUTTON} ${
                        tagName ? classes[tagName] : classes.TO_INVESTIGATE
                      } ${uniqueTagNames.length > 1 && classes.collapsedButton}`}
                    >
                      {toTitleCase(tagName)}
                    </Button>
                  ))}
                </Box>
                )}
                <Box className={classes.stepIcons}>
                  <Box mx={2} display="flex" alignItems="center">
                    <img width={20} src={stopwatchIcon} alt="stopwatch" />
                    <Typography variant="body2" color="textSecondary" ml={0.5}>
                      {getDuration(item['duration-ms']) || 0}
                    </Typography>
                  </Box>
                  <IconButton size="small">
                    <ExpandMoreIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            <Collapse in={isExpanded}>
              <Box
                className={classes.stepDetails}
                onClick={(e) => e.stopPropagation()}
              >
                {filteredMethods
                  .filter((method) => {
                    const methodTagName = tagMap[method.name];
                    const methodTags = tagFilter[item.name];
                    if (!methodTags || methodTags.length === 0) {
                      return true;
                    }
                    return methodTags.includes(methodTagName);
                  })
                  .map((method, index) => {
                    const hasFailed = method.status === TestStatus.FAIL;
                    const tagClass = tagMap[method.name];
                    return (
                      <Box
                        key={index}
                        display="flex"
                        alignItems="center"
                        className={classes.category}
                        onClick={
                    method?.name !== 'fetchSuiteConfiguration'
                    && method?.name !== 'setUp'
                    && method?.name !== 'tearDown'
                      ? () => {
                        changeTest({
                          id,
                          test: method,
                          testName: item.name,
                          project,
                        });
                        openTest(true);
                      }
                      : undefined
                  }
                      >
                        <Box display="flex" alignItems="center">
                          <CircleIcon
                            className={hasFailed ? classes.iconError : classes.iconSuccess}
                          />
                          <Typography variant="body2" className={classes.metricTitle}>
                            {method?.name}
                          </Typography>
                        </Box>
                        <Box className={classes.stepSummary}>
                          {hasFailed && (
                          <Box sx={{ display: 'flex', gap: 1 }}>
                            <Button
                              className={`${classes.TAG_BUTTON} ${
                                tagClass ? classes[tagClass] : classes.TO_INVESTIGATE
                              }`}
                            >
                              {toTitleCase(tagClass || 'TO_INVESTIGATE')}
                            </Button>
                          </Box>
                          )}
                          <Box className={classes.stepIcons}>
                            <Box
                              ml={2}
                              minWidth={100}
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-end"
                            >
                              <img width={20} src={stopwatchIcon} alt="stopwatch" />
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                style={{ marginLeft: 8 }}
                              >
                                {getDuration(method['duration-ms']) || 0}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
              </Box>
            </Collapse>
          </Box>
        ) : null;
      })}
    </>
  );
};

export default SeleniumXMLTestSteps;

SeleniumXMLTestSteps.propTypes = {
  tags: {},
  testData: [],
  changeTest: PropTypes.func,
  openTest: PropTypes.func,
  project: PropTypes.string,
  id: PropTypes.string,
};

SeleniumXMLTestSteps.defaultProps = {
  tags: {},
  testData: [],
  changeTest: () => { },
  openTest: () => { },
  project: '',
  id: '',
};
