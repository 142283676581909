/* eslint-disable react/forbid-prop-types */
import React from 'react';
import {
  Box, Input, Button, Typography, FormControl, InputLabel,
} from '@mui/material';
import PropTypes from 'prop-types';
import VirtualizedDropdown from './VirtualizedDropdown';

const DynamicForm = ({
  fields, onChange, onSubmit, onClear,
}) => {
  const handleFieldChange = (fieldKey, value) => {
    onChange(fieldKey, value);
  };
  const hasValues = fields.some((field) => field.value && field.value !== '');
  return (
    <Box display="flex" flexWrap="wrap" alignItems="center" gap={2}>
      {fields.map((field) => {
        switch (field.type) {
          case 'text':
            return (
              <Box key={field.key} sx={{ minWidth: 200 }}>
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel size="small">{field.label}</InputLabel>
                  <Input
                    value={field.value || ''}
                    onChange={(e) => handleFieldChange(field.key, e.target.value)}
                  />
                </FormControl>
              </Box>
            );
          case 'dropdown':
            return (
              <Box key={field.key} sx={{ minWidth: 200 }}>
                <FormControl sx={{ width: '100%', borderRadius: '20px' }}>
                  <InputLabel size="small">{field.label}</InputLabel>
                  <VirtualizedDropdown
                    data={field.options || []}
                    value={field.value || ''}
                    onChange={(e) => handleFieldChange(field.key, e.target.value)}
                    placeholder={field.placeholder || 'Select'}
                    renderItem={(item) => <span>{item.label}</span>}
                    label={field.label}
                    isCurved
                    isSearchable
                    size="small"
                  />
                </FormControl>
              </Box>
            );
          default:
            return null;
        }
      })}
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Button variant="contained" color="primary" onClick={onSubmit}>
          Apply
        </Button>
        {onClear && hasValues && (
          <Typography
            onClick={onClear}
            style={{
              marginLeft: 16, fontSize: '13px', color: '#393939', cursor: 'pointer',
            }}
          >
            Clear All
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default DynamicForm;

DynamicForm.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['text', 'dropdown']).isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.any,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.any.isRequired,
        }),
      ),
      placeholder: PropTypes.string,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClear: PropTypes.func,
};

DynamicForm.defaultProps = {
  onClear: null,
};
