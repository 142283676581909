import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  FormControl,
  TextField,
  Grid,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import { createJiraInstanceProject } from '../../services/integrations';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '8px',
    borderBottom: `3px solid ${theme.palette.warning.main}`,
    borderLeft: `3px solid ${theme.palette.warning.main}`,
    padding: '20px',
    width: '500px',
  },
  '& .MuiDialogTitle-root': {
    fontWeight: 600,
    fontSize: '24px',
  },
  '& .MuiDialogContent-root': {
    paddingTop: '20px !important',
  },
}));

const CreateJiraProjectModal = ({
  open, handleClose, projectId,
  companyId, resourceId, refreshProjectList,
}) => {
  const [projectKey, setProjectKey] = useState('');
  const [projectName, setProjectName] = useState('');
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });

  const createProject = async () => {
    try {
      const response = await createJiraInstanceProject({
        companyId,
        projectId,
        resourceId,
        key: projectKey,
        projectName,
      });
      if (response) {
        handleClose(false);
        refreshProjectList(true);
      }
    } catch (error) {
      setSnackbar({
        state: true,
        navigateText: '',
        message: error.data.message,
        severity: 'error',
      });
    }
  };

  return (
    <StyledDialog open={open} onClose={() => handleClose(false)}>
      <DialogTitle>
        Create Project
        <IconButton
          aria-label="close"
          onClick={() => handleClose(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ paddingTop: '20px' }}>
        <>
          <FormControl sx={{ width: '100%', marginBottom: '16px' }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Project Key"
                value={projectKey}
                onChange={(e) => {
                  const { value } = e.target;
                  setProjectKey(value.toLocaleUpperCase());
                }}
                placeholder="Project key"
                error={!/^[A-Z][A-Z0-9]*$/.test(projectKey)}
                helperText={
                  !/^[A-Z][A-Z0-9]*$/.test(projectKey)
                    ? 'Project keys must start with an uppercase letter, followed by one or more uppercase alphanumeric characters.'
                    : ''
                }
              />
            </Grid>
          </FormControl>

          <FormControl sx={{ width: '100%', marginBottom: '16px' }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Project Name"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                placeholder="Enter title"
              />
            </Grid>
          </FormControl>
        </>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="warning">
          Cancel
        </Button>
        <Button disabled={!projectKey || !projectName} onClick={createProject} color="primary">
          Submit
        </Button>
      </DialogActions>
      {
        snackbar.state && (
          <NavigateSnackbar snackbarObj={snackbar} setSnackbar={setSnackbar} />
        )
      }
    </StyledDialog>
  );
};

CreateJiraProjectModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
  refreshProjectList: PropTypes.func.isRequired,
};

export default CreateJiraProjectModal;
