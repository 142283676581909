import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box, Typography, Chip,
  Tooltip,
  ListItemText,
  Collapse,
  IconButton,
  Button,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import stopwatchIcon from '../../../assets/stopwatch.png';
import DonutChart from '../../chart/DonutChart';
import { getDuration } from '../../Home/TestData';
import { CypressTestStatus } from '../../../utils/constants';
import { useCommonContext } from '../../header/context';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F5F5F5',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  summaryHeaderBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingInline: theme.spacing(2),
    border: '1px solid #000000',
    margin: theme.spacing(2, 0),
    boxShadow: '-5px 5px 0px 0px #FFC72B',
    borderRadius: theme.spacing(1),
  },
  summaryTitleWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
  },
  summaryTitle: {
    fontWeight: 600,
    fontSize: '19px',
  },
  stepSummary: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  testSummmaryBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    border: '1px solid #E1E1E1',
    margin: theme.spacing(2, 0, 0),
    boxShadow: 'none',
    borderRadius: theme.spacing(1),
  },
  testHeaderMain: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  testWrapper: {
    width: '100%',
    padding: theme.spacing(1, 2),
    border: '1px solid #E1E1E1',
    margin: theme.spacing(2, 0, 0),
    boxShadow: 'none',
    borderRadius: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  stepHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
    margin: theme.spacing(0, 2),
    borderBottom: '1px solid #e0e0e0',
  },
  stepDetails: {
    padding: theme.spacing(2),
  },
  stepTitle: {
    display: 'flex',
    alignItems: 'baseline',
  },
  stepIcons: {
    display: 'flex',
    alignItems: 'center',
  },
  TAG_BUTTON: {
    borderRadius: '24px',
    color: '#fff',
    fontWeight: '500',
    padding: theme.spacing(0.5, 2),
    whiteSpace: 'nowrap',
  },
  TO_INVESTIGATE: {
    backgroundColor: '#000000',
    color: '#fff',
    '&:hover': {
      background: '#A6A6A6',
    },
  },
  PRODUCT_BUG: {
    backgroundColor: '#FF0000',
    '&:hover': {
      background: '#BF0000',
    },
  },
  AUTOMATION_BUG: {
    backgroundColor: '#FFC72B',
    '&:hover': {
      background: '#CC9A22',
    },
  },
  NO_DEFECT: {
    backgroundColor: '#1D6AE5',
    '&:hover': {
      background: '#154EB0',
    },
  },
  ENVIRONMENT_ISSUE: {
    backgroundColor: '#5FD726',
    '&:hover': {
      background: '#4AA61E',
    },
  },
  iconError: {
    fontSize: '12px',
    color: '#FF0000',
    marginRight: theme.spacing(1),
  },
  iconSuccess: {
    fontSize: '12px',
    color: '#5FD726',
    marginRight: theme.spacing(1),
  },
  browserInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  subSuites: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(1.5, 2),
    margin: theme.spacing(1, 0),
    background: '#fff',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid #E7E7E7',
  },
  suiteOutline: {
    padding: theme.spacing(1.5, 2),
    margin: theme.spacing(1, 0),
    borderRadius: theme.shape.borderRadius,
    border: '1px solid #E7E7E7',
  },
  category: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(1.5, 2),
    margin: theme.spacing(1, 0),
    background: '#fff',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid #E7E7E7',
    '&:hover': {
      background: '#EFEFEF',
    },
  },
  chipSuccess: {
    color: '#fff',
    backgroundColor: '#5FD726',
    borderColor: '#5FD726',
    height: '25px',
  },
  chipFailure: {
    color: '#fff',
    backgroundColor: '#FF0000',
    borderColor: '#FF0000',
    height: '25px',
  },
  chipSkipped: {
    color: '#fff',
    backgroundColor: '#FFC72B',
    borderColor: '#FFC72B',
    height: '25px',
  },
  chipIgnored: {
    color: '#fff',
    backgroundColor: '#000000',
    borderColor: '#000000',
    height: '25px',
  },
}));

const CypressTestSteps = ({
  tags, testData, changeTest, openTest, project, id,
}) => {
  const classes = useStyles();
  const { selectedfilter } = useCommonContext();
  const [expandedSuites, setExpandedSuites] = useState({});

  const handleExpandClick = (suiteTitle) => {
    setExpandedSuites((prev) => ({
      ...prev,
      [suiteTitle]: !prev[suiteTitle],
    }));
  };

  // Filter function to apply to individual tests
  const applyFilterToTests = (tests) => {
    if (!selectedfilter) return tests; // No filter applied

    return tests?.filter((test) => {
      if (test.state === selectedfilter) return true; // Match selected filter
      if (selectedfilter === 'passed' && test.passes?.length) return true;
      if (selectedfilter === 'failed' && test.failures?.length) return true;
      if (selectedfilter === 'skipped' && test.skipped?.length) return true;
      return false;
    });
  };

  // Recursive filter function for suites (intersuites)
  const applyFilterToSuites = (suites) => suites?.filter((suite) => {
    // Apply filter to the tests within this suite
    const filteredTests = applyFilterToTests(suite.tests);

    // Apply filter recursively to nested suites (intersuites)
    const filteredIntersuites = applyFilterToSuites(suite.suites);

    // Render the suite if it contains any filtered tests or filtered nested suites
    return filteredTests.length > 0 || filteredIntersuites.length > 0;
  });

  const renderTests = ({ tests }) => {
    const filteredTests = applyFilterToTests(tests);
    return <>

      {filteredTests?.map((test) => {
        const tagClass = tags[test?.title]?.find((tag) => tag[test?.title])?.[test?.title]?.tagName;
        const isFailed = test.state === CypressTestStatus.FAILED;
        return <Box
          onClick={
            () => {
              changeTest({
                id,
                test,
                testName: test.title,
                project,
              });
              openTest(true);
            }
          }
          key={test.uuid}
          display="flex"
          alignItems="center"
          className={classes.category}
        >
          <Box display="flex" alignItems="center">
            <CircleIcon
              className={
                test.state === CypressTestStatus.FAILED
                  ? classes.iconError
                  : classes.iconSuccess
              }
            />
            <Typography variant="body2" className={classes.metricTitle}>
              {test?.title}
            </Typography>
          </Box>
          <Box className={classes.stepSummary}>
            {isFailed
              && <Button
                className={`${classes.TAG_BUTTON} ${tagClass !== undefined
                  ? classes[tagClass]
                  : classes.TO_INVESTIGATE
                }`}
              >
                {tagClass || 'TO INVESTIGATE'}
              </Button>}
            <Box className={classes.stepIcons}>
              <Box mx={2} display="flex" alignItems="center">
                <img width={20} src={stopwatchIcon} alt="stopwatch" />
                <Typography variant="body2" color="textSecondary" ml={0.5}>{getDuration(test?.duration) || 0}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>;
      })}
    </>;
  };

  const renderSuites = ({ suites }) => {
    const filteredSuites = applyFilterToSuites(suites);
    return (
      <>
        {filteredSuites?.map((suite) => {
          const passed = suite?.passes?.length;
          const failed = suite?.failures?.length;
          const pending = suite?.pending?.length;
          const skipped = suite?.skipped?.length;
          return <Box
            className={classes.suiteOutline}
            sx={{
              display: 'flex', flexDirection: 'column', width: '100%', cursor: 'pointer',
            }}
            onClick={(e) => { e.stopPropagation(); handleExpandClick(suite.title); }}
          >
            <Box className={classes.testHeaderMain}>
              <Box>
                <ListItemText
                  sx={{
                    '& .MuiListItemText-primary': {
                      fontWeight: 600,
                      fontSize: '17px',
                    },
                    '& .MuiListItemText-secondary': {
                      fontSize: '13px',
                    },
                  }}
                  primary={suite?.title}
                  secondary={suite?.fullFile}
                />
              </Box>
              <Box className={classes.stepSummary}>
                {!!(passed || failed || pending || skipped)
                  && <>
                    <Box className={classes.stepIcons}>
                      <Box mx={2} display="flex" alignItems="center">
                        <img src={stopwatchIcon} width={20} alt="Stopwatch" />
                        <Typography variant="body2" color="textSecondary" ml={0.5}>
                          {getDuration(suite?.duration || 0)}
                        </Typography>
                      </Box>
                    </Box>
                    <Box display="flex" gap={1}>
                      <Tooltip title="Passed">
                        <Chip
                          variant="outlined"
                          label={passed}
                          className={classes.chipSuccess}
                        />
                      </Tooltip>
                      <Tooltip title="Failed">
                        <Chip
                          variant="outlined"
                          label={failed}
                          className={classes.chipFailure}
                        />
                      </Tooltip>
                      <Tooltip title="Pending">
                        <Chip
                          variant="outlined"
                          label={0}
                          className={classes.chipIgnored}
                        />
                      </Tooltip>
                      <Tooltip title="Skipped">
                        <Chip
                          variant="outlined"
                          label={skipped}
                          className={classes.chipSkipped}
                        />
                      </Tooltip>
                    </Box>
                    <Box>
                      <DonutChart
                        donutChartSuitesData={{
                          passed: passed || 0,
                          failed: failed || 0,
                          skipped: skipped || 0,
                          ignored: 0,
                        }}
                      />
                    </Box>
                  </>}
                <IconButton size="small">
                  <ExpandMoreIcon />
                </IconButton>
              </Box>
            </Box>
            <Collapse in={expandedSuites[suite.title] || false}>

              {suite.suites.length > 0 && <Box className={classes.subSuites}>
                {renderSuites({ suites: suite?.suites })}
              </Box>}

              {suite.tests.length > 0 && renderTests({ tests: suite?.tests })}
            </Collapse>
          </Box>;
        })}
      </>
    );
  };

  return (
    <>
      {testData?.map((item, index) => {
        const { suites } = item;
        return renderSuites({ suites, index });
      })}
    </>
  );
};

export default CypressTestSteps;

CypressTestSteps.propTypes = {
  tags: {},
  testData: [],
  changeTest: PropTypes.func,
  openTest: PropTypes.func,
  project: PropTypes.string,
  id: PropTypes.string,
};

CypressTestSteps.defaultProps = {
  tags: {},
  testData: [],
  changeTest: () => { },
  openTest: () => { },
  project: '',
  id: '',
};
