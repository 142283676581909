import axiosInstance from './axiosInstance';

const addSlackWebHook = async (payload) => {
  try {
    const response = await axiosInstance.post(`/generate/saveOrUpdateSlackWebhookUrl?slackWebhookUrl=${payload.slackUrl}&companyId=${payload.companyId}&projectId=${payload.projectId}`);
    return response.status === 200 || 201;
  } catch (err) {
    throw err.response;
  }
};

const getSlackWebHook = async (payload) => {
  try {
    const response = await axiosInstance.get(`/generate/getSlackWebhookUrl?companyId=${payload.companyId}&projectId=${payload.projectId}`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const deleteSlackWebHook = async (id) => {
  try {
    const response = await axiosInstance.delete(`/generate/deleteSlackWebhookUrl?webhookUrlId=${id}`);
    return response.status === 200;
  } catch (err) {
    throw err.response;
  }
};

// const reNewPluginAccessKeyToken = async () => {
//   try {
//     const response = await axiosInstance.get('/generate/reNewToken');
//     return response.status === 200;
//   } catch (err) {
//     throw err.response;
//   }
// };
const createJiraProject = async (jiraProject) => {
  try {
    const response = await axiosInstance.post('/integration-jira/saveJiraAccount', {
      ...jiraProject,
    });
    return response.status === 200;
  } catch (err) {
    throw err.response;
  }
};

const jiraAccountPageByCurrentUser = async (payload) => {
  try {
    const response = await axiosInstance.get(`/integration-jira/jiraAccountPageByCurrentUser?companyId=${payload.companyId}&projectId=${payload.projectId}&pageNumber=${payload.page}&pageSize=${payload.rowsPerPage}`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const createJiraTicket = async (ticket) => {
  try {
    const response = await axiosInstance.post('/integration-jira/createJiraTicket', {
      ...ticket,
    });
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const getAllJiraCommentsByIssueId = async ({ jiraTicketId, projectId, companyId }) => {
  try {
    const response = await axiosInstance.get(`/integration-jira/getJiraComments?jiraTicketId=${jiraTicketId}&projectId=${projectId}&companyId=${companyId}`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const deleteJiraAccount = async (id) => {
  try {
    const response = await axiosInstance.delete(`/integration-jira/delete/${id}`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const getJiraProjects = async (payload) => {
  try {
    const response = await axiosInstance.get(`/integration-jira/getAllProjects?resourceId=${payload?.resourceId}&companyId=${payload?.companyId}&projectId=${payload?.projectId}`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const getPriorities = async (payload) => {
  try {
    const response = await axiosInstance.get(`/integration-jira/getPriorities?resourceId=${payload?.resourceId}&companyId=${payload?.companyId}&projectId=${payload?.projectId}`);
    return response.data?.values;
  } catch (err) {
    throw err.response;
  }
};

const getResources = async (payload) => {
  try {
    const response = await axiosInstance.get(`/integration-jira/getResources?projectId=${payload?.projectId}&companyId=${payload?.companyId}`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const getJiraIssues = async (payload) => {
  try {
    const response = await axiosInstance.get(`/integration-jira/getProjectIssues?resourceId=${payload?.resourceId}&companyId=${payload?.companyId}&projectId=${payload?.projectId}&jiraProjectId=${payload?.jiraProjectId}`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const createJiraInstanceProject = async (jiraProject) => {
  try {
    const response = await axiosInstance.post('/integration-jira/createProject', {
      ...jiraProject,
    });
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const jiraAccountByProjectName = async (projectName) => {
  try {
    const response = await axiosInstance.get(`/integration-jira/jiraAccountByProjectName?projectName=${projectName}`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const getAllProjectIssueType = async (payload) => {
  try {
    const response = await axiosInstance.get(`/integration-jira/getJiraAccountIssueType?resourceId=${payload?.resourceId}&companyId=${payload?.companyId}&projectId=${payload?.projectId}&jiraProjectId=${payload?.jiraProjectId}`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const fetchJiraTickets = async (query) => {
  try {
    const response = await axiosInstance.get(`/integration-jira/jiraTicketPageByCurrentUser?${query}`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

export {
  addSlackWebHook,
  getSlackWebHook,
  deleteSlackWebHook,
  createJiraProject,
  jiraAccountPageByCurrentUser,
  createJiraTicket,
  jiraAccountByProjectName,
  getAllProjectIssueType,
  fetchJiraTickets,
  deleteJiraAccount,
  getAllJiraCommentsByIssueId,
  getResources,
  getJiraProjects,
  getPriorities,
  createJiraInstanceProject,
  getJiraIssues,
  // reNewPluginAccessKeyToken,
};
