import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  FormControl,
  InputLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import VirtualizedDropdown from '../VirtualizedDropdown';
import getJiraUrl from '../../utils/jira';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '8px',
    borderBottom: `3px solid ${theme.palette.warning.main}`,
    borderLeft: `3px solid ${theme.palette.warning.main}`,
    padding: '20px',
  },
  '& .MuiDialogTitle-root': {
    fontWeight: 600,
    fontSize: '24px',
  },
  '& .MuiDialogContent-root': {
    paddingTop: '20px !important',
  },
}));

const JiraIntegrationModal = ({
  open, handleClose, companyId, companyDropdown, projects, setCompanyId,
}) => {
  const [projectId, setProjectId] = useState('');
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const values = { companyId, projectId };
    window.location.href = getJiraUrl(values);
  };

  useEffect(() => {
    if (projects.length > 0) {
      setProjectId(projects[0]?.projectId);
    }
  }, [projects]);

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="jira-integration-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="jira-integration-dialog-title">
        Jira Configuration
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleFormSubmit} style={{ minWidth: 200 }}>
          <FormControl sx={{ width: '100%', backgroundColor: '#fff', marginBottom: 2 }}>
            <InputLabel size="small">Company</InputLabel>
            <VirtualizedDropdown
              data={companyDropdown?.map((company) => ({
                value: company.companyId,
                label: company.companyName,
              }))}
              value={companyId}
              onChange={(e) => {
                setCompanyId(e.target.value);
                setProjectId('');
              }}
              placeholder="No Company Found"
              renderItem={(item) => <span>{item.label}</span>}
              label="Company"
              size="small"
              isSearchable
            />
          </FormControl>

          <FormControl sx={{ width: '100%', backgroundColor: '#fff', marginBottom: 2 }}>
            <InputLabel size="small">Project</InputLabel>
            <VirtualizedDropdown
              data={projects?.map((project) => ({
                value: project.projectId,
                label: project.projectName,
              }))}
              value={projectId}
              onChange={(e) => setProjectId(e.target.value)}
              placeholder="Select a Project"
              renderItem={(item) => <span>{item.label}</span>}
              label="Project"
              size="small"
              isSearchable
            />
          </FormControl>

          <DialogActions>
            <Button variant="outlined" onClick={handleClose} color="warning">
              Cancel
            </Button>
            <Button disabled={!companyId || !projectId} variant="contained" type="submit" color="primary">
              Connect To Jira
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
      {snackbar.state && (
        <NavigateSnackbar snackbarObj={snackbar} setSnackbar={setSnackbar} />
      )}
    </StyledDialog>
  );
};

JiraIntegrationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  companyDropdown: PropTypes.arrayOf(
    PropTypes.shape({
      companyId: PropTypes.string.isRequired,
      companyName: PropTypes.string.isRequired,
    }),
  ),
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      projectId: PropTypes.string.isRequired,
      projectName: PropTypes.string.isRequired,
    }),
  ),
  companyId: PropTypes.string,
  setCompanyId: PropTypes.func,
};

JiraIntegrationModal.defaultProps = {
  companyDropdown: [],
  projects: [],
  companyId: '',
  setCompanyId: () => {},
};

export default JiraIntegrationModal;
