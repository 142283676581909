import dayjs from 'dayjs';
import moment from 'moment-timezone';

const donutChartData1 = {
  chartData: [
    ['Task', 'Number of Tests'],
    ['Passed Tests', 11],
    ['Failed Tests', 24],
  ],
  options: {
    pieHole: 0.4,
    legend: 'none',
    width: 380,
    height: 380,
    slices: {
      0: { color: '#10B981' },
      1: { color: '#E33936' },
    },
  },
};

const renderChartValues = (pass = 0, fail = 0) => ({
  chartData: [
    ['Task', 'Number of Tests'],
    ['Passed Tests', pass],
    ['Failed Tests', fail],
  ],
  options: {
    pieHole: 0.4,
    legend: 'none',
    width: 380,
    height: 380,
    slices: {
      0: { color: '#26b99a' },
      1: { color: '#f04438' },
    },
  },
});
const renderHtmlChartValues = (pass = 0, fail = 0) => ({
  chartData: [
    ['Task', 'Number of Tests'],
    ['Passed Tests', pass],
    ['Failed Tests', fail],
  ],
  options: {
    pieHole: 0.4,
    legend: 'none',
    width: 380,
    height: 380,
    slices: {
      0: { color: '#26b99a' },
      1: { color: '#f04438' },
    },
  },
});

const renderChartValues1 = (pass = 0, fail = 0) => ({
  chartData: [
    ['Task', 'Number of Tests'],
    ['Passed Tests', pass],
    ['Failed Tests', fail],
  ],
  options: {
    pieHole: 0.4,
    legend: 'none',
    width: 380,
    height: 380,
    slices: {
      0: { color: '#10B981' },
      1: { color: '#E33936' },
    },
  },
});

const donutChartData2 = {
  chartData: [
    ['tests', 'Number of Tests'],
    ['Passed Tests', 1],
    ['Failed Tests', 0],
    ['', 0.02],
  ],
  options: {
    pieHole: 0.6,
    pieSliceText: 'none',
    pieStartAngle: 50,
    slices: {
      0: { color: '#10B981' },
      1: { color: 'transparent' },
      2: { color: 'transparent' },
    },
    tooltip: { trigger: 'none' },
    legend: 'none',
    width: 380,
    height: 380,
  },
};
const lineChartData1 = (total = 0, passed = 0, failed = 0, skipped = 0) => ({
  chartData: [
    ['string', 'Status'],
    ['number', 'value'],
  ],
  Xaxis: [
    ['Total', total],
    ['Passed', passed],
    ['Failed', failed],
    ['Skipped', skipped],
  ],
  options: {
    height: 600,
    width: 700,
    legend: 'none',
  },
});

const parseDate = (createdAt) => {
  if (!createdAt) {
    return '';
  }
  const cleanedDate = createdAt?.split(' ');
  let updatedDate;

  if (cleanedDate[1] === 'IST') {
    const convertedDate = moment
      .tz(cleanedDate[0], 'Asia/Calcutta')
      .utc()
      .format();
    const localTimeZone = moment.tz.guess();
    updatedDate = moment
      .utc(convertedDate)
      .tz(localTimeZone)
      .format('MMM DD, YYYY, LTS');
  } else {
    const localTimeZone = moment.tz.guess();
    updatedDate = moment
      .utc(cleanedDate[0])
      .tz(localTimeZone)
      .format('MMM DD, YYYY, LTS');
  }

  return updatedDate;
};

const getProjectFormattedDate = (createdAt) => {
  const userTimezone = moment.tz.guess();
  const date = moment.utc(createdAt).tz(userTimezone);
  const formattedDate = date.format('MMM DD, YYYY  |  h:mm A (z)');
  return formattedDate;
};

const parseDateChart = (createdAt) => {
  const cleanedDate = createdAt?.split(' ');
  let updatedDate;

  if (cleanedDate[1] === 'IST') {
    const convertedDate = moment
      .tz(cleanedDate[0], 'Asia/Calcutta')
      .utc()
      .format();
    const localTimeZone = moment.tz.guess();
    updatedDate = moment
      .utc(convertedDate)
      .tz(localTimeZone)
      .format('DD/MM/YYYY LT');
  } else {
    const localTimeZone = moment.tz.guess();
    updatedDate = moment
      .utc(cleanedDate[0])
      .tz(localTimeZone)
      .format('DD/MM/YYYY LT');
  }

  return updatedDate;
};

const convertDateUtils = (createAt) => {
  if (!createAt) {
    return '';
  }
  const [dateStr] = createAt.split(' ');
  const newReportDate = new Date(dateStr);
  const day = newReportDate.getDate();
  const month = newReportDate.toLocaleString('default', { month: 'short' });
  const year = newReportDate.getFullYear();
  const time = parseDateChart(createAt)?.split(' ')[1];

  return `${day} ${month} ${year},${time}`;
};

const calculateDateRange = (filter) => {
  const endDate = dayjs(); // Current date
  let startDate;

  switch (filter) {
    case '1D':
      startDate = endDate.subtract(1, 'day');
      break;
    case '7D':
      startDate = endDate.subtract(7, 'day');
      break;
    case '30D':
      startDate = endDate.subtract(30, 'day');
      break;
    case '2M':
      startDate = endDate.subtract(2, 'month');
      break;
    case '3M':
      startDate = endDate.subtract(3, 'month');
      break;
    case '6M':
      startDate = endDate.subtract(6, 'month');
      break;
    case '1Y':
      startDate = endDate.subtract(1, 'year');
      break;
    case '2Y':
      startDate = endDate.subtract(2, 'year');
      break;
    default:
      startDate = null;
  }

  return {
    startDate: startDate ? startDate.format('YYYY-MM-DD') : null,
    endDate: endDate.format('YYYY-MM-DD'),
  };
};

export {
  donutChartData1,
  donutChartData2,
  lineChartData1,
  renderChartValues,
  renderChartValues1,
  renderHtmlChartValues,
  parseDate,
  parseDateChart,
  convertDateUtils,
  calculateDateRange,
  getProjectFormattedDate,
};
