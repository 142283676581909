import macIcon from '../assets/apple-svgrepo-com.png';
import windowsIcon from '../assets/windows-applications-svgrepo-com.png';
import linuxIcon from '../assets/linux-svgrepo-com.png';
import chromeIcon from '../assets/chrome-color-svgrepo-com.png';
import edgeIcon from '../assets/edge-svgrepo-com.png';
import safariIcon from '../assets/safari-svgrepo-com.png';
import firefoxIcon from '../assets/firefox-svgrepo-com.png';
import androidIcon from '../assets/android-color-svgrepo-com.svg';

export const TestStatus = {
  FAIL: 'FAIL',
  PASS: 'PASS',
  SKIP: 'SKIP',
  IGNORE: 'IGNORE',
  ALL: 'ALL',
};
export const PlatwrightTestStatus = {
  FAILED: 'failed',
  PASSED: 'passed',
  SKIPPED: 'skipped',
  FLAKY: 'flaky',
  ALL: 'all',
};
export const CypressTestStatus = {
  FAILED: 'failed',
  PASSED: 'passed',
  SKIPPED: 'skipped',
  PENDING: 'pending',
  PASSES: 'passes',
  FAILURES: 'failures',
  ALL: 'all',
};
export const UserRole = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  QA_MANAGER: 'QA_MANAGER',
  TEST_ANALYST: 'TEST_ANALYST',
  USER: 'USER',
};
export const UserRolesOject = {
  SUPER_ADMIN: 'SUPER ADMIN',
  ADMIN: 'ADMIN',
  QA_MANAGER: 'QA MANAGER',
  TEST_ANALYST: 'TEST ANALYST',
  USER: 'USER',
};
export const UserType = {
  EXTERNAL: 'EXTERNAL',
  INTERNAL: 'INTERNAL',
};
export const UserStatus = {
  STATUS: 'ACTIVE',
};
export const GroupStatus = {
  STATUS: 'ACTIVE',
};
export const ProfileStatus = {
  STATUS: 'ACTIVE',
};
export const UserPermissionsObject = {
  UPLOAD_REPORT: 'Upload Report',
  INVITE_USER: 'Invite User',
  COMPANY: 'Create Company',
  ROLE: 'Create Role',
  PROJECT: 'Create Project',
  INVITE_PROJECT: 'Invite Project',
  MAVEN_PLUGIN: 'Maven Plugin',
  VIEW_REPORT: 'View Report',
  GUEST_USER: 'Guest User',
  INVITE_REPORT: 'Invite Report',
  TEAM: 'View Team',
  SLACK_INTEGRATION: 'Slack Integration',
  JIRA_INTEGRATION: 'Jira Integration',
};
export const UserPermission = {
  UPLOAD_REPORT: 'UPLOAD_REPORT',
  INVITE_USER: 'INVITE_USER',
  COMPANY: 'COMPANY',
  ROLE: 'ROLE',
  PROJECT: 'PROJECT',
  INVITE_PROJECT: 'INVITE_PROJECT',
  MAVEN_PLUGIN: 'MAVEN_PLUGIN',
  VIEW_REPORT: 'VIEW_REPORT',
  GUEST_USER: 'GUEST_USER',
  TEAM: 'TEAM',
  INVITE_REPORT: 'INVITE_REPORT',
  SLACK_INTEGRATION: 'SLACK_INTEGRATION',
  JIRA_INTEGRATION: 'JIRA_INTEGRATION',
};
export const ProjectStatus = {
  ACTIVE: 'ACTIVE',
  BlOCK: 'BLOCK',
};
export const InviteStatus = {
  ACCEPTED: 'ACCEPT',
  PENDING: 'PENDING',
  BLOCKED: 'BLOCKED',
  ACCEPT: 'ACCEPT',
  BLOCK: 'BLOCK',
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
};
export const HtmlStatus = {
  FAILED: 'FAILED',
  PASSED: 'PASSED',
  SKIPPED: 'SKIPPED',
};
export const FileType = {
  XML: 'XML',
  HTML: 'HTML',
  PLAYWRIGHT: 'PLAY_WRIGHT',
  CYPRESS: 'CYPRESS',
  SELENIUM: 'SELENIUM',
};
export const SortingType = {
  REPORTNAME: 'REPORTNAME',
  DATE: 'DATE',
  PASSED: 'PASSED',
  FAILED: 'FAILED',
};
export const XMLReport = {
  name: 'Selenium / Automation Report',
};
export const HTMLReport = {
  name: 'Selenium / Automation Report',
};
export const PLAYWRIGHTReport = {
  name: 'Playwright / Automation Report',
};

export const CYPRESSReport = {
  name: 'Cypress / Automation Report',
};

export const ReportSchema = {
  [FileType.XML]: XMLReport,
  [FileType.HTML]: HTMLReport,
  [FileType.PLAYWRIGHT]: PLAYWRIGHTReport,
  [FileType.CYPRESS]: CYPRESSReport,
  [FileType.SELENIUM]: XMLReport,
};

export const DashboardEnum = {
  SELENIUM: 'SELENIUM',
  PLAYWRIGHT: 'PLAY_WRIGHT',
  CYPRESS: 'CYPRESS',
};

export const TagEnum = {
  PRODUCT_BUG: 'Product Bug',
  AUTOMATION_BUG: 'Automation Bug',
  NO_DEFECT: 'No Defect',
  TO_INVESTIGATE: 'To Investigate',
  ENVIRONMENT_ISSUE: 'Environment Issue',
};

export const chipEnum = {
  passed: 'Passed',
  failed: 'Failed',
  ignored: 'Ignored',
  skipped: 'Skipped',
  flaky: 'Flaky',
  retried: 'Retried',
  pending: 'Pending',
};

export const projectEnum = {
  PLAY_WRIGHT: 'Playwright',
  SELENIUM: 'Selenium',
  CYPRESS: 'Cypress',
};

export const browserEnum = {
  FIREFOX: 'firefox',
  EDGE: 'microsoftedge',
  CHROME: 'chrome',
  SAFARI: 'safari',
};

export const platformEnum = {
  MAC: 'mac',
  WINDOWS: 'windows',
  LINUX: 'linux',
  ANDROID: 'android',
};

export const browserIcons = {
  [browserEnum.CHROME]: chromeIcon,
  [browserEnum.FIREFOX]: firefoxIcon,
  [browserEnum.EDGE]: edgeIcon,
  [browserEnum.SAFARI]: safariIcon,
};

export const plateformIcons = {
  [platformEnum.MAC]: macIcon,
  [platformEnum.WINDOWS]: windowsIcon,
  [platformEnum.LINUX]: linuxIcon,
  [platformEnum.ANDROID]: androidIcon,
};
