/* eslint-disable no-template-curly-in-string */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { DashboardEnum } from '../../utils/constants';
import inviteUserService from '../../services/inviteUser.service';
import userService from '../../services/user.service';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';

const renderSeleniumIntegrationSteps = (code) => [
  {
    label: 'Step 1',
    title: 'Copy Required Snippet',
    description: (
      <>
        <Typography>
          Copy the required snippet from TestReport.io.
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="pre" sx={{ whiteSpace: 'pre-wrap', margin: 0 }}>
            <code>{code}</code>
          </Typography>
        </Box>
        <Typography mt={1}>
          Open the
          <code>{' pom.xml '}</code>
          file in your Selenium framework.
        </Typography>
        <Typography>
          Paste the code into your
          <code>{' pom.xml '}</code>
          file to configure the necessary dependencies.
        </Typography>
      </>
    ),
  },
  {
    label: 'Step 2',
    title: 'Set Source File Path',
    description: (
      <>
        <Typography>
          Set the path of the
          <code>{' testng-results.xml '}</code>
          file in your projects where the file generates inside the OutputDirectory tag.
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            Example:
            <br />
            <code>{'<sourceFilePath>${project.build.directory}/test-output/testng-results.xml</sourceFilePath>'}</code>
          </Typography>
        </Box>
      </>
    ),
  },
  {
    label: 'Step 3',
    title: 'Set Screenshot Path',
    description: (
      <>
        <Typography>
          If you integrate screenshots, define the
          <code>{' screenshotPath '}</code>
          in your project directory for storing failed case screenshots.
          If not required, remove the
          <code>{' <screenshotImagePath> '}</code>
          tag.
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            Example:
            <br />
            <code>{'<screenshotImagePath>${project.build.directory}/test-output/screenshots/</screenshotImagePath>'}</code>
          </Typography>
        </Box>
      </>
    ),
  },
  {
    label: 'Step 4',
    title: 'Run the Project',
    description: (
      <>
        <Typography>
          Execute the following Maven command in your terminal
          to initiate the build and test process:
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p" sx={{ whiteSpace: 'pre-wrap', margin: 0 }}>
            Example:
            <br />
            <code>
              {`
mvn clean integration-test

<Run |maven command with your specified goal which you mentioned in pom.xml>`}
            </code>
          </Typography>
        </Box>
      </>
    ),
  },
  {
    label: 'Step 5',
    title: 'Add below line inside your makeScreenshot method, So you’ll get image url as well on report:',
    description: (
      <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
        <Typography variant="body2" component="p">
          <code>
            {`Reporter.log("<a href='" + "https://app.testreport.io/qa-java-api/api/v1/user/getImage/SCREENSHOT/"+
            destFile.getName() + "'> <img src='"+ "https://app.testreport.io/qa-java-api/api/v1/user/getImage/SCREENSHOT/" + destFile.getName()+ "' height='250' width='500'/> ");`}
          </code>
        </Typography>
      </Box>
    ),
  },
];

const renderPlaywrightIntegrationSteps = (code, handleCopy) => [
  {
    label: 'Step 1',
    title: 'Create the Configuration File',
    description: (
      <>
        <Typography>
          First, create a configuration file named
          <code>{' testreport.config.js '}</code>
          in the root directory of your Playwright project.
        </Typography>
        <Typography pt={2}>Code Snippet:</Typography>
        <Box
          sx={{
            backgroundColor: '#f5f5f5',
            padding: 2,
            fontFamily: 'monospace',
          }}
        >
          <div>
            <Tooltip title="Copy to Clipboard">
              <ContentCopyIcon
                color="primary"
                style={{ cursor: 'pointer', marginBottom: '10px' }}
                onClick={handleCopy}
              />
            </Tooltip>
            <Typography variant="body2" component="pre" sx={{ whiteSpace: 'pre-wrap', margin: 0 }}>
              <code>{code}</code>
            </Typography>
          </div>
        </Box>
        <Typography variant="body2" component="p" mt={2}>
          Copy the above code snippet and paste it into the
          <code>
            {' testreport.config.js '}
          </code>
          file.
        </Typography>
      </>
    ),
  },
  {
    label: 'Step 2',
    title: 'Set targetDir',
    description: (
      <>
        <Typography>
          In the configuration file, specify the path where the
          <code>{' index.html '}</code>
          test report file is generated.
        </Typography>
        <Typography pt={2}>Example Path:</Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            <code>
              {"targetDir: 'C:\\Users\\user\\Downloads\\demo\\playwright-report\\',"}
            </code>
          </Typography>
        </Box>
        <Typography pt={2}>
          {`Ensure to use double
          slashes (\\\\) in the path for compatibility.`}
        </Typography>
      </>
    ),
  },
  {
    label: 'Step 3',
    title: 'Set screenshotPath',
    description: (
      <>
        <Typography>
          Point this to the directory
          where screenshots for failed tests are stored.
        </Typography>
        <Typography pt={2}>Example Path:</Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            <code>
              {"screenshotsDir: 'C:\\Users\\user\\Downloads\\demo\\playwright-report\\data\\',"}
            </code>
          </Typography>
        </Box>
      </>
    ),
  },
  {
    label: 'Step 4',
    title: 'Install the TestReport.io Dependency',
    description: (
      <>
        <Typography>
          Next, install the TestReport.io dependency
          by running the following command in your terminal:
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            <code>
              npm install testreport-io
            </code>
          </Typography>
        </Box>
      </>
    ),
  },
  {
    label: 'Step 5',
    title: 'Add the Upload Script',
    description: (
      <>
        <Typography>
          Add the following script to your
          <code>{' package.json '}</code>
          file to enable uploading your test reports:
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            <code>
              {`"scripts": {
                "upload": "testreport-io upload"
              }`}
            </code>
          </Typography>
        </Box>
      </>
    ),
  },
  {
    label: 'Step 6',
    title: 'Run the Upload Command',
    description: (
      <>
        <Typography>
          Finally, upload your test report and screenshots to
          TestReport.io by running the following command in your terminal:
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            <code>
              npm run upload
            </code>
          </Typography>
        </Box>
      </>
    ),
  },
];

const renderCypressIntegrationSteps = (code, handleCopy) => [
  {
    label: 'Step 1',
    title: 'Create the Configuration File',
    description: (
      <>
        <Typography>
          Create a configuration file named
          <code>{' testreport.config.js '}</code>
          in the root directory of your Cypress project.
        </Typography>
        <Typography pt={2}>Code Snippet:</Typography>
        <Box
          sx={{
            backgroundColor: '#f5f5f5',
            padding: 2,
            fontFamily: 'monospace',
          }}
        >
          <div>
            <Tooltip title="Copy to Clipboard">
              <ContentCopyIcon
                color="primary"
                style={{ cursor: 'pointer', marginBottom: '10px' }}
                onClick={handleCopy}
              />
            </Tooltip>
            <Typography variant="body2" component="pre" sx={{ whiteSpace: 'pre-wrap', margin: 0 }}>
              <code>{code}</code>
            </Typography>
          </div>
        </Box>
        <Typography variant="body2" component="p" mt={2}>
          Copy the above code snippet and paste it into the
          <code>
            {' testreport.config.js '}
          </code>
          file.
        </Typography>
      </>
    ),
  },
  {
    label: 'Step 2',
    title: 'Set targetDir',
    description: (
      <>
        <Typography>
          In the configuration file, specify the path where the
          <code>{' index.html '}</code>
          test report file is generated.
        </Typography>
        <Typography pt={2}>Example Path:</Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            <code>
              {"targetDir: 'D:\\Cypress-Framework\\cypress\\results\\cypress-mochawesome-reporter\\',"}
            </code>
          </Typography>
        </Box>
        <Typography pt={2}>
          {`Ensure to use double
          slashes (\\\\) in the path for compatibility.`}
        </Typography>
      </>
    ),
  },
  {
    label: 'Step 3',
    title: 'Set screenshotsDir',
    description: (
      <>
        <Typography>
          Specify the directory path where
          Cypress stores screenshots for failed tests.
        </Typography>
        <Typography pt={2}>Example Path:</Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            <code>
              {"sourceFilePath: ‘D:\\Cypress-Framework\\cypress\\screenshots\\',"}
            </code>
          </Typography>
        </Box>
      </>
    ),
  },
  {
    label: 'Step 4',
    title: 'Set videoDir',
    description: (
      <>
        <Typography>
          Specify the directory path where Cypress saves test videos.
        </Typography>
        <Typography pt={2}>Example Path:</Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            <code>
              {"videoDir: 'D:\\Cypress-Framework\\cypress\\videos\\',"}
            </code>
          </Typography>
        </Box>
      </>
    ),
  },
  {
    label: 'Step 5',
    title: 'Install the TestReport.io Dependency',
    description: (
      <>
        <Typography>
          Next, install the TestReport.io dependency
          by running the following command in your terminal:
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            <code>
              npm install testreport-io
            </code>
          </Typography>
        </Box>
      </>
    ),
  },
  {
    label: 'Step 6',
    title: 'Add the Upload Script',
    description: (
      <>
        <Typography>
          Add the following script to your
          <code>{' package.json '}</code>
          file to enable uploading your test reports:
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            <code>
              {`"scripts": {
                "upload": "testreport-io upload"
              }`}
            </code>
          </Typography>
        </Box>
      </>
    ),
  },
  {
    label: 'Step 7',
    title: 'Run the Upload Command',
    description: (
      <>
        <Typography>
          To, upload your test report and screenshots to
          TestReport.io  use the following command in your terminal:
        </Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 1 }}>
          <Typography variant="body2" component="p">
            <code>
              npm run upload
            </code>
          </Typography>
        </Box>
      </>
    ),
  },
];

const StepUpSteps = ({ companyDetails }) => {
  const [token, setToken] = useState(null);
  const [projectUrl, setprojectUrl] = useState('');
  const [userDetails, setUserDetails] = useState({
    projectId: companyDetails?.projectId || '',
    companyId: companyDetails?.companyId || '',
    selectedFramework: companyDetails?.selectedFramework || '',
  });
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });
  const [code, setCode] = useState('');
  const handleCopy = () => {
    navigator.clipboard.writeText(code)
      .then(() => {
        setSnackbar({
          navigateText: '',
          severity: 'success',
          state: true,
          message: 'Copied to clipboard',
        });
      })
      .catch(() => {
        setSnackbar({
          navigateText: '',
          severity: 'error',
          state: true,
          message: 'Failed to copy',
        });
      });
  };

  useEffect(() => {
    if (projectUrl && token && userDetails.selectedFramework) {
      let codeString = '';

      switch (userDetails.selectedFramework) {
        case DashboardEnum.SELENIUM:
          codeString = `
<build>
  <plugins>
    <plugin>
      <groupId>com.decipherzone</groupId>
      <artifactId>qa-parse-maven-plugin</artifactId>
      <version>1.5.15</version>
      <executions>
        <execution>
          <phase>integration-test</phase>
          <configuration>
            <projectBaseUrl>${projectUrl}</projectBaseUrl>
            <screenshotImagePath>/failsafe-reports/chrome/screenshots/</screenshotImagePath>
            <sourceFilePath>/platform-server/testng-results.xml</sourceFilePath>
            <projectAccessKey>${token}</projectAccessKey>
          </configuration>
          <goals>
            <goal>xmltojson</goal>
          </goals>
        </execution>
      </executions>
    </plugin>
  </plugins>
</build>`;
          break;

        case DashboardEnum.PLAYWRIGHT:
          codeString = `module.exports = {
    projectAccessKey:'${token}',
    projectBaseUrl: '${projectUrl}',
    targetDir: '<generated test report index.html directory path>',
    screenshotsDir: '<screenshots directory path>',
  };`;
          break;

        case DashboardEnum.CYPRESS:
          codeString = `module.exports = {
    projectAccessKey: '${token}',
    projectBaseUrl: '${projectUrl}',
    targetDir: '<generated test report index.html directory path>',
    screenshotsDir: '<screenshots directory path>',
    videoDir: '<videos directory path>'
  };`;
          break;

        default:
          codeString = '';
      }

      setCode(codeString);
    }
  }, [projectUrl, token, userDetails]);

  const getUserDetails = async () => {
    try {
      const user = await userService.userInfo();
      setUserDetails({
        projectId: user.company.projectResponses[0].projectId,
        companyId: user.company.companyId,
        companyName: user.company.companyName,
        projectName: user.company.projectResponses[0].projectName,
        selectedFramework: user.company.projectResponses[0].framework,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const accessToken = async () => {
      if (!token && userDetails.companyId && userDetails.projectId) {
        try {
          const res = await inviteUserService.accessToken(
            userDetails.companyId,
            userDetails.projectId,
          );
          if (res) {
            setToken(res?.accessKey);
            setprojectUrl(res?.projectBasicUrl);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
    accessToken();
  }, [userDetails]);

  const renderIntegrationContent = () => {
    switch (userDetails?.selectedFramework) {
      case DashboardEnum.SELENIUM:
        return renderSeleniumIntegrationSteps(code);
      case DashboardEnum.PLAYWRIGHT:
        return renderPlaywrightIntegrationSteps(code, handleCopy);
      case DashboardEnum.CYPRESS:
        return renderCypressIntegrationSteps(code, handleCopy);
      default:
        return [];
    }
  };

  useEffect(() => {
    if (userDetails !== undefined || !userDetails?.companyId) getUserDetails();
  }, []);

  return (
    <>
      <div className="multistep-main-wrap">
        <div style={{ width: '100%', padding: '0 8px' }} className="multistep-2-block-details">
          <div className="multistep-detail-block" style={{ position: 'relative' }}>
            <div style={{ display: 'flex', flexDirection: 'column-reverse' }} className="multistep-detail-block">
              {/* {userDetails?.selectedFramework === DashboardEnum.SELENIUM && <Box
                sx={{
                  backgroundColor: '#f5f5f5',
                  padding: 2,
                  borderRadius: 2,
                  boxShadow: 1,
                  fontFamily: 'monospace',
                }}
              >
                <div>
                  <Tooltip title="Copy to Clipboard">
                    <ContentCopyIcon
                      color="primary"
                      style={{ cursor: 'pointer', marginBottom: '10px' }}
                      onClick={() => navigator.clipboard.writeText(code)}
                    />
                  </Tooltip>
                  <Typography variant="body2"
                  component="pre" sx={{ whiteSpace: 'pre-wrap', margin: 0 }}>
                    <code>{code}</code>
                  </Typography>
                </div>
              </Box>} */}
              <Box sx={{ width: '100%', padding: 4 }}>
                {userDetails?.selectedFramework === DashboardEnum.SELENIUM && <>
                  <Box>
                    <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333' }}>Prerequisite:</Typography>
                    <Box pl={1}>
                      <Typography variant="h6" mt={2} sx={{ fontWeight: 'bold', color: '#333' }}>1. Add the Failsafe Plugin:</Typography>
                      <Typography my={1}>
                        {`Ensure the Maven Failsafe plugin is added outside the
                        <pluginManagement> section in your pom.xml file. also specify the path
                        to your XML configuration file.`}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant="h5" mt={4} sx={{ fontWeight: 'bold', color: '#333' }}>Steps to Integrate Selenium Project</Typography>
                </>}
                <Stepper activeStep={-1} orientation="vertical">
                  {renderIntegrationContent()?.length
                    ? renderIntegrationContent()?.map((step, index) => (
                      <Step key={index}>
                        <div className="copy-icon">
                          <StepLabel>
                            <Typography variant="p" sx={{ fontWeight: 'light', color: '#333' }}>
                              {step.label}
                            </Typography>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>
                              {step.title}
                            </Typography>
                          </StepLabel>
                          {step.label === 'Step 1' && <Tooltip title="Copy to Clipboard">
                            <ContentCopyIcon
                              color="primary"
                              style={{ cursor: 'pointer', marginBottom: '10px' }}
                              onClick={handleCopy}
                            />
                          </Tooltip>}
                        </div>
                        <Box sx={{ marginTop: 2, paddingLeft: 4 }}>
                          <Typography variant="body1" sx={{ color: '#555' }}>
                            {step.description}
                          </Typography>
                        </Box>
                      </Step>
                    )) : <p>Select a valid framework to see integration instructions.</p>}
                </Stepper>
              </Box>
            </div>
          </div>
        </div>
      </div>
      {
        snackbar.state
        && (
          <NavigateSnackbar
            snackbarObj={snackbar}
            setSnackbar={setSnackbar}
          />
        )
      }
    </>
  );
};

export default StepUpSteps;

StepUpSteps.propTypes = {
  companyDetails: PropTypes.shape({
    projectId: PropTypes.number,
    companyId: PropTypes.number,
    selectedFramework: PropTypes.string,
  }),
};

StepUpSteps.defaultProps = {
  companyDetails: {},
};
