/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import {
  Box, Typography, Grid, Paper, Chip,
  Tooltip,
} from '@mui/material';
import stopwatchIcon from '../../../assets/stopwatch.png';
import { chipEnum, FileType } from '../../../utils/constants';
import TestReviewPage from './TestReviewPage';
import SeleniumXMLTestSteps from '../TestSteps/SeleniumXMLTestSteps';
import PlaywrightTestSteps from '../TestSteps/PlaywrightTestSteps';
import useHandleChipClick from '../../../utils/FilterChipHook';
import SeleniumHTMLTestSteps from '../TestSteps/SeleniumHTMLTestSteps';
import CypressTestSteps from '../TestSteps/CypressTestSteps';
import { useCommonContext } from '../../header/context';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F5F5F5',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  summaryHeaderBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingInline: theme.spacing(2),
    border: '1px solid #000000',
    margin: theme.spacing(2, 0),
    boxShadow: '-5px 5px 0px 0px #FFC72B',
    borderRadius: theme.spacing(1),
  },
  summaryTitleWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
  },
  summaryTitle: {
    fontWeight: 600,
    fontSize: '19px',
  },
  stepSummary: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  testSummmaryBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    border: '1px solid #E1E1E1',
    margin: theme.spacing(2, 0, 0),
    boxShadow: 'none',
    borderRadius: theme.spacing(1),
  },
  testHeaderMain: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  testWrapper: {
    width: '100%',
    padding: theme.spacing(1, 2),
    border: '1px solid #E1E1E1',
    margin: theme.spacing(2, 0, 0),
    boxShadow: 'none',
    borderRadius: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  stepHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
    margin: theme.spacing(0, 2),
    borderBottom: '1px solid #e0e0e0',
  },
  stepDetails: {
    padding: theme.spacing(2),
  },
  stepTitle: {
    display: 'flex',
    alignItems: 'baseline',
  },
  stepIcons: {
    display: 'flex',
    alignItems: 'center',
  },
  TAG_BUTTON: {
    borderRadius: '24px',
    color: '#fff',
    fontWeight: '500',
    padding: theme.spacing(0.5, 2),
    whiteSpace: 'nowrap',
  },
  TO_INVESTIGATE: {
    backgroundColor: '#D9D9D9',
    color: '#000',
    '&:hover': {
      background: '#A6A6A6',
    },
  },
  PRODUCT_BUG: {
    backgroundColor: '#FF0000',
    '&:hover': {
      background: '#BF0000',
    },
  },
  AUTOMATION_BUG: {
    backgroundColor: '#FFC72B',
    '&:hover': {
      background: '#CC9A22',
    },
  },
  NO_DEFECT: {
    backgroundColor: '#1D6AE5',
    '&:hover': {
      background: '#154EB0',
    },
  },
  ENVIRONMENT_ISSUE: {
    backgroundColor: '#5FD726',
    '&:hover': {
      background: '#4AA61E',
    },
  },
  iconError: {
    fontSize: '12px',
    color: '#FF0000',
    marginRight: theme.spacing(1),
  },
  iconSuccess: {
    fontSize: '12px',
    color: '#5FD726',
    marginRight: theme.spacing(1),
  },
  browserInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  category: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(1.5, 2),
    margin: theme.spacing(1, 0),
    background: '#fff',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid #E7E7E7',
    '&:hover': {
      background: '#EFEFEF',
    },
  },
  chipSuccess: {
    color: '#fff',
    backgroundColor: '#5FD726',
    borderColor: '#5FD726',
    height: '25px',
  },
  chipFailure: {
    color: '#fff',
    backgroundColor: '#FF0000',
    borderColor: '#FF0000',
    height: '25px',
  },
  chipSkipped: {
    color: '#fff',
    backgroundColor: '#FFC72B',
    borderColor: '#FFC72B',
    height: '25px',
  },
  chipIgnored: {
    color: '#fff',
    backgroundColor: '#000000',
    borderColor: '#000000',
    height: '25px',
  },
}));

const TestReportList = ({
  openTest, setOpenTest, tags, chartData,
  testData, setSelectedTests, selectedTests,
  setBugTab, reportType, chipsData,
  imageBlob, setAnalyzing, setImageBlob,
}) => {
  const classes = useStyles();
  const { setSelectedFilter } = useCommonContext();
  const handleChipClick = useHandleChipClick();

  const getChipClass = (label) => {
    switch (label) {
      case 'passed':
        return classes.chipSuccess;
      case 'failed':
        return classes.chipFailure;
      case 'skipped':
        return classes.chipSkipped;
      case 'ignored':
      case 'pending':
      case 'flaky':
      case 'retried':
        return classes.chipIgnored;
      default:
        return classes.chipLabel;
    }
  };

  useEffect(() => () => setSelectedFilter(null), []);

  return (
    <>
      {!openTest ? <>
        <Paper className={`no-page-break ${classes.summaryHeaderBox}`}>
          <Box className={classes.summaryTitleWrapper}>
            <Box>
              <Typography className={classes.summaryTitle} variant="h6">{chartData?.testReportName}</Typography>
            </Box>
            <Box className={classes.stepSummary}>
              <Box mx={2} className={classes.stepIcons}>
                <Box display="flex" alignItems="center">
                  <img src={stopwatchIcon} width={20} alt="Stopwatch" />
                  <Typography variant="body2" color="textSecondary" ml={0.5}>{chartData?.duration}</Typography>
                </Box>
              </Box>
              <Box display="flex" gap={2}>
                {chipsData && chipsData?.map(({ label, val }) => {
                  if (label === 'total') {
                    return null;
                  }
                  const chipClass = getChipClass(label);
                  const tooltipTitle = chipEnum[label];
                  return (
                    <Tooltip title={tooltipTitle} key={label}>
                      <Chip
                        variant="outlined"
                        label={val}
                        className={chipClass}
                        onClick={val > 0 ? () => handleChipClick(label) : undefined}
                      />
                    </Tooltip>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper className={classes.testSummmaryBox}>
              {reportType === FileType.XML && <SeleniumXMLTestSteps
                tags={tags}
                testData={testData}
                project={chartData?.projectName}
                id={chartData?.id}
                // testName={report?.testReportName}
                changeTest={setSelectedTests}
                openTest={setOpenTest}
              />}
              {reportType === FileType.HTML && <SeleniumHTMLTestSteps
                tags={tags}
                testData={testData}
                project={chartData?.projectName}
                id={chartData?.id}
                // testName={report?.testReportName}
                changeTest={setSelectedTests}
                openTest={setOpenTest}
              />}
              {reportType === FileType.PLAYWRIGHT && <PlaywrightTestSteps
                tags={tags}
                testData={testData}
                project={chartData?.projectName}
                id={chartData?.id}
                // testName={report?.testReportName}
                changeTest={setSelectedTests}
                openTest={setOpenTest}
              />}
              {reportType === FileType.CYPRESS && <CypressTestSteps
                tags={tags}
                testData={testData}
                project={chartData?.projectName}
                id={chartData?.id}
                // testName={report?.testReportName}
                changeTest={setSelectedTests}
                openTest={setOpenTest}
              />}
            </Paper>
          </Grid>
        </Grid>
      </> : <>
        <TestReviewPage
          chartData={chartData}
          setBugTab={setBugTab}
          tags={tags}
          test={selectedTests.test}
          setSelectedTests={setSelectedTests}
          id={selectedTests?.id || ''}
          selectedTestName={selectedTests?.testName}
          project={selectedTests?.project}
          reportType={reportType}
          imageBlob={imageBlob}
          setAnalyzing={setAnalyzing}
          setImageBlob={setImageBlob}
        />
      </>}
    </>
  );
};

export default TestReportList;

TestReportList.propTypes = {
  chipsData: [],
  chartData: {},
  testData: [],
  selectedTests: {},
  setImageBlob: PropTypes.func,
  setSelectedTests: PropTypes.func,
  imageBlob: PropTypes.string,
  setAnalyzing: PropTypes.func,
  reportType: PropTypes.string,
  tags: {},
  setBugTab: PropTypes.func,
  openTest: PropTypes.bool,
  setOpenTest: PropTypes.func,
};

TestReportList.defaultProps = {
  chipsData: [],
  chartData: {},
  testData: [],
  reportType: '',
  selectedTests: {},
  setImageBlob: () => { },
  setSelectedTests: () => { },
  setAnalyzing: () => { },
  imageBlob: '',
  tags: {},
  setBugTab: () => { },
  openTest: false,
  setOpenTest: () => { },
};
