import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useCommonContext } from '../header/context';
import { UserPermission, UserRole } from '../../utils/constants';
import companyService from '../../services/company.service';
import reportService from '../../services/report.service';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import { deleteJiraAccount, jiraAccountPageByCurrentUser } from '../../services/integrations';
import { CustomPagination, StyledTablePagination, tableStyles } from '../header/style';
import VirtualizedDropdown from '../VirtualizedDropdown';

const JiraIntegration = () => {
  const classes = tableStyles();
  const { user, setUser } = useCommonContext();
  const [companyDropdown, setCompanyDropdown] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [projectId, setprojectId] = useState(null);
  const [project, setProject] = useState([]);
  const [jiraProjects, setJiraProjects] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(async () => {
    if (companyId && projectId) {
      try {
        const data = await jiraAccountPageByCurrentUser({
          companyId, projectId, page: page > 0 ? page - 1 : 0, rowsPerPage,
        });
        setJiraProjects(data.content);
        setTotalPages(data.totalPages);
      } catch (error) {
        setSnackbar({
          navigateText: '',
          severity: 'error',
          state: true,
          message: `${error?.data?.message}`,
        });
      }
    }
  }, [page, rowsPerPage, companyId, projectId, refresh]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const companyList = async () => {
    try {
      const res = await companyService.getAll();
      setCompanyId(res[0]?.companyId);
      setprojectId(res[0]?.projects[0]?.projectId);
      setCompanyDropdown(res);
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.data?.message}`,
      });
    }
  };

  const handleCompanyChange = (e) => {
    const id = e.target.value;
    setCompanyId(id);
    setprojectId('');
  };
  useEffect(() => {
    if (user?.role?.roleName === UserRole.ADMIN) {
      setCompanyId(user?.company?.companyId);
      setprojectId(user?.company?.projectResponses[0]?.projectId);
    }
    companyList();
  }, [user?.role?.roleId]);

  const handleDelete = async (id) => {
    try {
      const res = await deleteJiraAccount(id);
      if (res) {
        setUser({ ...user, jiraConfigured: false });
        setRefresh((prev) => !prev);
        setSnackbar({
          navigateText: '',
          severity: 'success',
          state: true,
          message: `${res}`,
        });
      }
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.data?.message}`,
      });
    }
  };

  const getProjectName = async () => {
    if (companyId) {
      try {
        const res = await reportService.getProjectById(companyId);
        setProject(res);
      } catch (error) {
        setSnackbar({
          navigateText: '',
          severity: 'error',
          state: true,
          message: `${error?.data?.message}`,
        });
      }
    }
  };

  useEffect(() => {
    getProjectName();
  }, [user?.role?.roleId, companyId]);

  return (
    <>
      <Box
        sx={{
          borderRadius: '4px',
          background: '#fff',
        }}
      >
        {user?.role?.userPermissionsList.includes(UserPermission.JIRA_INTEGRATION) && (
          <>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={8} sm={4}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel size="small">
                      Company
                    </InputLabel>
                    <VirtualizedDropdown
                      data={companyDropdown && companyDropdown?.map((company) => ({
                        value: company.companyId,
                        label: company.companyName,
                      }))}
                      isCurved
                      value={companyId}
                      onChange={handleCompanyChange}
                      placeholder="No Company Found"
                      renderItem={(item) => (
                        <span>{item.label}</span>
                      )}
                      label="Company"
                      size="small"
                      isSearchable
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={8} sm={4}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel size="small">
                      Projects
                    </InputLabel>
                    <VirtualizedDropdown
                      data={project && project?.map((menuitem) => ({
                        value: menuitem?.projectId,
                        label: menuitem?.projectName,
                      }))}
                      isCurved
                      isSearchable
                      value={projectId || ''}
                      onChange={(e) => { setprojectId(e.target.value); }}
                      placeholder="No Project Found"
                      renderItem={(item) => (
                        <span>{item.label}</span>
                      )}
                      label="Projects"
                      size="small"
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} mt={4}>
                <Paper className={classes.summaryBox}>
                  <Box className={classes.summaryBoxIn}>
                    <TableContainer component={Paper} className={classes.tableContainer}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.headerCell} />
                            <TableCell className={classes.headerCell}>User Name</TableCell>
                            <TableCell className={classes.headerCell}>Lead Id</TableCell>
                            <TableCell className={classes.headerCell}>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {jiraProjects?.length ? jiraProjects?.map((row) => (
                            <TableRow key={row?.id}>
                              <TableCell>
                                <Avatar src={row?.picture} alt={row?.name} />
                              </TableCell>
                              <TableCell>{row?.name}</TableCell>
                              <TableCell>{row?.accountId}</TableCell>
                              <TableCell>
                                <Button color="error" variant="outlined" size="small" onClick={() => handleDelete(row.id)}>
                                  Unlink
                                </Button>
                              </TableCell>
                            </TableRow>
                          )) : (<TableRow>
                            <TableCell colSpan={5} align="center">
                              No Jira Account Setup.
                            </TableCell>
                          </TableRow>)}
                        </TableBody>
                      </Table>
                      {totalPages > 0 ? (
                        <Box className={classes.paginationContainer}>
                          <StyledTablePagination
                            count={totalPages * rowsPerPage}
                            totalPages={totalPages}
                            page={page - 1}
                            onPageChange={handleChangePage}
                            ActionsComponent={() => <CustomPagination
                              count={totalPages}
                              page={page}
                              onChange={handleChangePage}
                              variant="outlined"
                              shape="rounded"
                              showFirstButton
                              showLastButton
                            />}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Box>
                      ) : <></>}
                    </TableContainer>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
      {snackbar.state && (
        <NavigateSnackbar
          snackbarObj={snackbar}
          setSnackbar={setSnackbar}
        />
      )}
    </>
  );
};

export default JiraIntegration;
