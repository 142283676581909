import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  InputLabel,
  FormControl,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  ParentBox,
} from '../Inviteuser/style';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import companyService from '../../services/company.service';
import reportService from '../../services/report.service';
import { useCommonContext } from '../header/context';
import { UserPermission, UserRole } from '../../utils/constants';
import StepUpSteps from './StepUpSteps';
import VirtualizedDropdown from '../VirtualizedDropdown';

function AccessTokens({ framework }) {
  const [open, setOpen] = useState(false);
  const [companyDropdown, setCompanyDropdown] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [projectId, setprojectId] = useState(null);
  const [project, setProject] = useState(null);
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });
  const {
    user,
  } = useCommonContext();

  const companyList = async () => {
    try {
      const res = await companyService.getAll();
      const dropdown = res?.filter((item) => item?.projects[0]?.framework === framework);
      setCompanyId(dropdown[0]?.companyId);
      setprojectId(dropdown[0]?.projects[0]?.projectId);
      setCompanyDropdown(dropdown);
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error.message}`,
      });
    }
  };

  const handleCompanyChange = (e) => {
    const id = e.target.value;
    setOpen(false);
    setCompanyId(id);
    setprojectId('');
  };

  useEffect(() => {
    if (user?.role?.roleName === UserRole.ADMIN) {
      setCompanyId(user?.company?.companyId);
      setprojectId(user?.company?.projectResponses[0]?.projectId);
    }
    companyList();
  }, [user?.role?.roleId]);

  const getProjectName = async () => {
    if (companyId) {
      try {
        const res = await reportService.getProjectById(companyId);
        setProject(res);
      } catch (error) {
        setSnackbar({
          navigateText: '',
          severity: 'error',
          state: true,
          message: `${error.data.message}`,
        });
      }
    }
  };

  const getProjectinviteList = async () => {
    if (companyId) {
      try {
        const res = await reportService.getProjectinviteList();
        setProject(res);
      } catch (error) {
        setSnackbar({
          navigateText: '',
          severity: 'error',
          state: true,
          message: `${error.data.message}`,
        });
      }
    }
  };

  useEffect(() => {
    if (user?.role?.userPermissionsList.includes(UserPermission.MAVEN_PLUGIN)
      && user?.role?.userPermissionsList.includes(UserPermission.GUEST_USER)) {
      getProjectinviteList();
    } else {
      getProjectName();
    }
  }, [user?.role?.roleId, companyId]);

  useEffect(() => {
    if (projectId && companyDropdown?.length && project?.length) setOpen(true);
  }, [projectId, companyDropdown, project]);

  return (
    <>
      <ParentBox>
        <Box
          sx={{
            borderRadius: '4px',
            background: '#fff',
          }}
        >
          <div style={{ width: '66%' }}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={8} sm={4}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel size="small">
                      Company
                    </InputLabel>
                    <VirtualizedDropdown
                      data={companyDropdown && companyDropdown?.map((company) => ({
                        value: company.companyId,
                        label: company.companyName,
                      }))}
                      isCurved
                      isSearchable
                      value={companyId}
                      onChange={handleCompanyChange}
                      placeholder="No Company Found"
                      renderItem={(item) => (
                        <span>{item.label}</span>
                      )}
                      label="Company"
                      size="small"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={8} sm={4}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel size="small">
                      Projects
                    </InputLabel>
                    <VirtualizedDropdown
                      data={project && project?.map((menuitem) => ({
                        value: menuitem?.projectId,
                        label: menuitem?.projectName,
                      }))}
                      isCurved
                      isSearchable
                      value={projectId || ''}
                      onChange={(e) => { setOpen(false); setprojectId(e.target.value); }}
                      placeholder="No Project Found"
                      renderItem={(item) => (
                        <span>{item.label}</span>
                      )}
                      label="Projects"
                      size="small"
                    />
                  </FormControl>
                </Grid>
                {/* <Grid item xs={8} sm={4}>
                  <Button
                    type="submit"
                    onClick={() => { setOpen(true); }}
                    sx={{
                      borderRadius: '4px',
                    }}
                    size="large"
                    variant="contained"
                  >
                    <ButtonText> Click Here </ButtonText>
                  </Button>
                </Grid> */}
              </Grid>
            </Box>
          </div>

          {open && <StepUpSteps
            companyDetails={{
              projectId,
              companyId,
              selectedFramework: framework,
            }}
          />}
        </Box>
        {
          snackbar.state
          && (
            <NavigateSnackbar
              snackbarObj={snackbar}
              setSnackbar={setSnackbar}
            />
          )
        }
      </ParentBox>
    </>
  );
}

export default AccessTokens;

AccessTokens.propTypes = {
  framework: PropTypes.string,
};

AccessTokens.defaultProps = {
  framework: '',
};
