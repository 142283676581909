import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Button, Chip, CircularProgress, FormControl, FormHelperText, Grid, InputLabel,
  Link, MenuItem, Paper, TextField, TextareaAutosize, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import {
  createJiraTicket, fetchJiraTickets,
  getAllProjectIssueType, getJiraIssues, getJiraProjects, getPriorities, getResources,
} from '../../services/integrations';
import JiraIcon from '../../assets/jira-svgrepo-com.svg';
import CommonUtils from '../../utils/common/common';
import { getDuration } from '../Home/TestData';
import VirtualizedDropdown from '../VirtualizedDropdown';
import { useCommonContext } from '../header/context';
import { UserRole } from '../../utils/constants';
import CreateJiraProjectModal from './JiraProjectModal';
import getJiraUrl from '../../utils/jira';

const useStyles = makeStyles((theme) => ({
  buttonJira: {
    backgroundColor: '#1d6ae5',
    boxShadow: 'none',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#165bb5',
    },
    maxWidth: '125px',
  },
  inputFeild: {
    border: '1px solid #f0f0f0',
    boxShadow: 'none',
    borderRadius: theme.spacing(1),
    '&:focus-visible': {
      outlineColor: '#f1f1f1',
    },
  },
  titleWrapper: {
    fontWeight: 600,
    fontSize: '17px',
  },
  testSummmaryBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    border: '1px solid #E1E1E1',
    margin: theme.spacing(2, 0, 0),
    boxShadow: 'none',
    borderRadius: theme.spacing(1),
  },
}));
const JiraWarpper = ({
  project,
  test,
  id,
  selectedTestName,
  setSnackbarData,
  jiraTickets,
  setJiraTickets,
  reportType,
  testMethodName,
}) => {
  const classes = useStyles();
  const { user } = useCommonContext();
  const [jiraInstances, setJiraInstances] = useState([]);
  const [selectedInstance, setSelectedInstance] = useState('');
  const [jiraProjects, setJiraProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [priorities, setPriorities] = useState([]);
  const [selectedPriority, setSelectedPriroity] = useState('');
  const [issueTypes, setIssueTypes] = useState([]);
  const [issue, setIssue] = useState('');
  const [createJira, setCreateJira] = useState(false);
  const [ticket, setTicket] = useState(false);
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState(test?.testName || selectedTestName || '');
  const [loading, setLoading] = useState(false);
  const [createJiraProject, setCreateJiraProject] = useState(false);
  const [refreshProjectList, setRefreshProjectList] = useState(false);
  const [isPriority, setIsPriority] = useState(false);
  const [isParentPresent, setIsParentPresent] = useState(false);
  const [taskList, setTasksList] = useState([]);
  const [parentTask, setParentTask] = useState(null);
  const [showJiraConnect, setShowJiraConnect] = useState(false);
  const [hideJira, setHideJira] = useState(false);

  const { projectId, companyId } = test;

  const getJiraResources = async () => {
    try {
      const response = await getResources(
        { projectId, companyId },
      );
      setSelectedInstance(response[0]?.id);
      setJiraInstances(response);
    } catch (error) {
      if (user?.jiraConfigured) {
        setSnackbarData({
          navigateText: '',
          severity: 'error',
          state: true,
          message: `${error?.data?.message}`,
        });
      }
    }
  };

  const getAllJiraProjects = async () => {
    try {
      const response = await getJiraProjects(
        { projectId, companyId, resourceId: selectedInstance },
      );
      setSelectedProject(response[0]?.id);
      setJiraProjects(response);
    } catch (error) {
      setSnackbarData({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.data?.message}`,
      });
    }
  };

  const getIssueTypes = async () => {
    try {
      const response = await getAllProjectIssueType(
        {
          companyId, projectId, resourceId: selectedInstance, jiraProjectId: selectedProject,
        },
      );
      setIssueTypes(response);
    } catch (error) {
      setSnackbarData({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.data?.message}`,
      });
    }
  };

  const getAllPriorities = async () => {
    try {
      const response = await getPriorities({ projectId, companyId, resourceId: selectedInstance });
      setIsPriority(response.length === 0);
      setPriorities(response);
    } catch (error) {
      setSnackbarData({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.data?.message}`,
      });
    }
  };

  const getJiraTickets = async () => {
    setLoading(true);
    const testName = CommonUtils.replaceAmpersandWithAnd(selectedTestName);
    const filterAnd = `testReportId|eq|${id}&projectName|eq|${project}&testReportTestName|eq|${testName}`;
    const encodedFilterAnd = encodeURIComponent(filterAnd);
    const query = `filterAnd=${encodedFilterAnd}`;
    try {
      const response = await fetchJiraTickets(query);
      setJiraTickets(response);
    } catch (error) {
      setSnackbarData({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.data?.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleJiraTicket = async () => {
    try {
      setCreateJira(true);
      const res = await createJiraTicket({
        defectType: test?.tagClass || 'TO_INVESTIGATE',
        description,
        duration: getDuration(test.duration),
        exception: JSON.stringify(test?.exception),
        fileType: reportType,
        issueType: issue,
        projectName: project,
        testReportId: id,
        testReportTestName: CommonUtils.replaceAmpersandWithAnd(selectedTestName),
        title,
        testMethodName,
        companyId,
        projectId,
        priority: selectedPriority,
        jiraProjectId: selectedProject,
        jiraAccountDomainName: jiraInstances?.find(
          (instance) => instance.id === selectedInstance,
        )?.url,
        resourceId: selectedInstance,
        parentId: parentTask,
      });
      setTicket(res);
      setSnackbarData({
        navigateText: '',
        severity: 'success',
        state: true,
        message: 'Raised Issue Successfully ..!',
      });
    } catch (err) {
      setSnackbarData({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${err?.data?.message}`,
      });
    } finally {
      setCreateJira(false);
    }
  };

  const isSubTask = useMemo(() => {
    const selectedIssue = issueTypes && issueTypes?.find((issueType) => issueType?.id === issue);
    return selectedIssue?.subtask || false;
  }, [issue, issueTypes]) || false;

  const getAllIssues = async () => {
    try {
      const response = await getJiraIssues(
        {
          companyId, projectId, resourceId: selectedInstance, jiraProjectId: selectedProject,
        },
      );
      setIsParentPresent(response?.sections[0]?.issues.length === 0);
      setTasksList(response?.sections[0]?.issues || []);
    } catch (error) {
      setSnackbarData({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.data?.message}`,
      });
    }
  };

  useEffect(() => {
    if (isSubTask) {
      getAllIssues();
    }
  }, [isSubTask]);

  useEffect(() => {
    if (companyId && projectId) {
      getJiraResources();
    }
  }, []);

  useEffect(() => {
    if (selectedInstance) {
      getAllJiraProjects();
      getAllPriorities();
    }
  }, [selectedInstance, refreshProjectList]);

  useEffect(() => {
    if (selectedProject) {
      getIssueTypes(project);
    }
  }, [selectedProject]);

  useEffect(() => {
    if (project) getJiraTickets();
  }, [ticket]);

  useEffect(() => {
    const isSuperAdmin = user?.role?.roleName === UserRole.SUPER_ADMIN;
    const isAdmin = user?.role?.roleName === UserRole.ADMIN;

    if (isSuperAdmin && jiraInstances?.length === 0) {
      setShowJiraConnect(true);
    } else if (isAdmin && !user?.jiraConfigured) {
      setShowJiraConnect(true);
    } else if (!user?.jiraConfigured) {
      setHideJira(true);
    }
  }, [jiraInstances, user]);

  if (loading) {
    return <>
      <Box textAlign="center">
        <CircularProgress textAlign="center" />
      </Box>
    </>;
  }
  return (
    <>
      {(!showJiraConnect && !hideJira) ? <Paper className={classes.testSummmaryBox}>
        {(!jiraTickets?.content?.length) ? <>
          <Typography
            variant="subtitle1"
            className={classes.titleWrapper}
          >
            Create Jira Ticket
          </Typography>
          <Grid container display="flex" alignItems="center" spacing={2} paddingBlock={2}>
            <Grid item xs={6}>
              <FormControl sx={{ width: '100%', backgroundColor: '#fff' }}>
                <InputLabel size="small">
                  Jira Instance
                </InputLabel>
                <VirtualizedDropdown
                  data={jiraInstances && jiraInstances?.map((instance) => ({
                    value: instance?.id,
                    label: <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: '100%' }}>
                      <Avatar sx={{ width: '20px', height: '20px' }} src={instance?.avatarUrl} />
                      <Typography ml={2} variant="body2">{instance?.name}</Typography>
                    </Box>,
                    searchableLabel: instance?.name,
                  }))}
                  value={selectedInstance}
                  onChange={(e) => {
                    setSelectedInstance(e.target.value);
                    setJiraProjects([]);
                    setSelectedProject('');
                    setIssue('');
                    setSelectedPriroity('');
                  }}
                  placeholder="No Instance Found"
                  renderItem={(item) => (
                    <span>{item.label}</span>
                  )}
                  label="Jira Instance"
                  isSearchable
                  className={classes.inputFeild}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: '100%', backgroundColor: '#fff' }}>
                <InputLabel size="small">
                  Project
                </InputLabel>
                <VirtualizedDropdown
                  data={jiraProjects && jiraProjects?.map((jiraProject) => ({
                    value: jiraProject?.id,
                    label: (
                      <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: '100%' }}>
                        <Avatar sx={{ width: '20px', height: '20px' }} src={jiraProject.avatarUrls['16x16']} />
                        <Typography ml={2} variant="body2">{jiraProject?.name}</Typography>
                      </Box>
                    ),
                    searchableLabel: jiraProject?.name,
                  }))}
                  value={selectedProject}
                  onChange={(e) => {
                    setSelectedProject(e.target.value);
                    setIssueTypes([]);
                    setIssue('');
                    setTasksList([]);
                    setParentTask(null);
                  }}
                  placeholder="No Project Found"
                  renderItem={(item) => (
                    <span>{item.label}</span>
                  )}
                  label="Project"
                  isSearchable
                  {...([UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(user?.role?.roleName) && {
                    onFooterButtonClick: () => setCreateJiraProject(true),
                    footerButtonLabel: '+ Create New Project',
                  })}
                  className={classes.inputFeild}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                select
                label="Issue Type"
                value={issue}
                InputProps={{
                  classes: {
                    root: classes.inputFeild,
                  },
                }}
                onChange={(e) => setIssue(e.target.value)}
              >
                {issueTypes.length > 0 ? issueTypes?.map((menuitem) => (
                  <MenuItem
                    value={menuitem?.id}
                    key={menuitem?.id}
                  >
                    {menuitem?.name}
                  </MenuItem>
                ))
                  : <MenuItem>No Record Found</MenuItem>}
              </TextField>
            </Grid>
            <Grid item xs={6} sx={{ position: 'relative' }}>
              <FormControl sx={{ width: '100%', backgroundColor: '#fff' }} error={isPriority}>
                <InputLabel size="small">Priority</InputLabel>
                <VirtualizedDropdown
                  data={
                    priorities
                    && priorities.map((priority) => ({
                      value: priority?.id,
                      label: (
                        <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: '100%' }}>
                          <Avatar sx={{ width: '20px', height: '20px' }} src={priority?.iconUrl} />
                          <Typography color={priority?.statusColor} ml={2} variant="body2">
                            {priority?.name}
                          </Typography>
                        </Box>
                      ),
                    }))
                  }
                  value={selectedPriority}
                  onChange={(e) => {
                    setSelectedPriroity(e.target.value);
                  }}
                  placeholder="No Priority Found"
                  renderItem={(item) => <span>{item.label}</span>}
                  label="Priority"
                  className={classes.inputFeild}
                />
              </FormControl>
              <FormHelperText
                sx={{
                  visibility: isPriority ? 'visible' : 'hidden', position: 'absolute', bottom: '-25%', ml: 1, color: 'red',
                }}
              >
                No priorities available. Please add priorities to your Jira account
              </FormHelperText>
            </Grid>
            {isSubTask
              && <Grid item xs={6} sx={{ position: 'relative' }}>
                <FormControl sx={{ width: '100%', backgroundColor: '#fff' }} error={isParentPresent}>
                  <InputLabel size="small">
                    Select Parent Task
                  </InputLabel>
                  <VirtualizedDropdown
                    data={taskList && taskList?.map((task) => ({
                      value: task?.key,
                      label: <>
                        <Chip
                          label={task?.key}
                          icon={<img width="18px" height="18px" src={JiraIcon} alt="jira" />}
                          style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            width: '100',
                            height: '20px',
                            textTransform: 'capitalize',
                            borderRadius: '9px',
                            fontSize: '10px',
                            fontWeight: 600,
                            background: '#f5eced',
                            color: '#000',
                            cursor: 'pointer',
                          }}
                        />
                      </>,
                      searchableLabel: task?.key,
                    }))}
                    isSearchable
                    value={parentTask}
                    onChange={(e) => {
                      setParentTask(e.target.value);
                    }}
                    placeholder="No Task Found"
                    renderItem={(item) => (
                      <span>{item.label}</span>
                    )}
                    label="Select Parent Task"
                    className={classes.inputFeild}
                  />
                </FormControl>
                <FormHelperText
                  sx={{
                    visibility: isParentPresent ? 'visible' : 'hidden', position: 'absolute', bottom: '-25%', ml: 1, color: 'red',
                  }}
                >
                  No Parent Task Present for this Project.
                </FormHelperText>
              </Grid>}
            <Grid item xs={isSubTask ? 6 : 12}>
              <TextField
                fullWidth
                label="Title"
                value={title}
                InputProps={{
                  classes: {
                    root: classes.inputFeild,
                  },
                }}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enter title"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                variant="outlined"
                fullWidth
                multiline
                label="Description"
                InputProps={{
                  inputComponent: TextareaAutosize,
                  classes: {
                    root: classes.inputFeild,
                  },
                  inputProps: {
                    minRows: 1,
                    style: { width: '100%' },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} textAlign="end">
              <Button className={classes.buttonJira} disabled={createJira || !issue || !description || !title || !selectedInstance || !selectedPriority || !selectedProject || isParentPresent} onClick={() => handleJiraTicket()} variant="contained">Create Jira</Button>
            </Grid>
          </Grid>
        </> : <>
          <Typography
            variant="subtitle1"
            fontSize="15px"
            fontWeight="bold"
            fontFamily="Open Sans"
            textAlign="left"
            color="#323232"
            p={1.3}
          >
            Raised Jira Ticket
          </Typography>
          <Box>
            {jiraTickets?.content?.map((item) => (
              <Chip
                label={item.jiraTicketId}
                component={Link}
                href={item?.ticketReturnUrl}
                target="_blank"
                icon={<img width="20px" height="20px" src={JiraIcon} alt="jira" />}
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  margin: '5px',
                  width: '100px',
                  height: '40px',
                  fontFamily: 'Open Sans',
                  textTransform: 'capitalize',
                  borderRadius: '9px',
                  fontSize: '10px',
                  fontWeight: 600,
                  background: '#f5eced',
                  color: '#000',
                  cursor: 'pointer',
                }}
              />
            ))}
          </Box>
        </>}
        {createJiraProject && <>
          <CreateJiraProjectModal
            projectId={projectId}
            companyId={companyId}
            resourceId={selectedInstance}
            open={createJiraProject}
            handleClose={setCreateJiraProject}
            refreshProjectList={setRefreshProjectList}
          />
        </>}
      </Paper> : <>
        {!hideJira && <Paper className={classes.testSummmaryBox}>
          <Button className={classes.buttonJira} onClick={() => { window.location.href = getJiraUrl({ companyId, projectId }); }} variant="contained" type="submit" color="primary">
            Connect To Jira
          </Button>
        </Paper>}
      </>}
    </>
  );
};

JiraWarpper.propTypes = {
  project: PropTypes.string,
  test: {},
  id: PropTypes.string,
  selectedTestName: PropTypes.string,
  setSnackbarData: PropTypes.func,
  jiraTickets: [],
  setJiraTickets: PropTypes.func,
  reportType: PropTypes.string,
  testMethodName: PropTypes.string,
};

JiraWarpper.defaultProps = {
  project: '',
  test: {},
  id: '-1',
  selectedTestName: '',
  setSnackbarData: PropTypes.func,
  jiraTickets: [],
  setJiraTickets: PropTypes.func,
  reportType: PropTypes.string,
  testMethodName: '',
};

export default JiraWarpper;
