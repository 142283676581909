import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import companyService from '../../services/company.service';
import {
  IconButtonStyle,
} from './style';
import { DashboardEnum } from '../../utils/constants';
import commonUtils from '../../utils/common/common';
import VirtualizedDropdown from '../VirtualizedDropdown';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '8px',
    borderBottom: `3px solid ${theme.palette.warning.main}`,
    borderLeft: `3px solid ${theme.palette.warning.main}`,
  },
}));

export default function ProjectModal({
  open,
  data,
  handleClose,
  handleSubmitForm,
  onChange,
  formData,
  handleChange,
  handleUpdateForm,
  projectEdit,
  projectShow,
  isEditProject,
}) {
  const [loading] = useState(false);
  const [opens, setOpens] = React.useState(false);
  const [companydata, setCompanyData] = useState(null);
  const { projectName } = data;
  const [projectError, setProjectError] = useState(false);
  const focusPoint = useRef(null);
  const onSubmit = () => {
    if (!projectName) {
      setProjectError(true);
      return;
    }
    handleSubmitForm();
  };
  const handleCloses = () => {
    setOpens(false);
  };

  const handleOpen = async () => {
    try {
      const res = await companyService.getAll();
      setCompanyData(res);
    } catch (error) {
      console.log(error);
    }
    setOpens(true);
  };
  React.useEffect(() => {
    handleOpen();
  }, []);
  return (
    <StyledDialog
      className="upload-report"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    // sx={{ width: '100%', maxWidth: '60%' }}
    >
      <DialogTitle id="alert-dialog-title">
        {isEditProject ? 'Edit a Project' : 'Create a Project'}
      </DialogTitle>
      <Divider />
      <IconButtonStyle
        aria-label="close"
        data-testid="UploadReport-box"
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButtonStyle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div style={{ width: '100%' }}>
            <Box sx={{ flexGrow: 1 }}>
              { isEditProject
                ? <Typography color="primary" sx={{ mb: 1 }} variant="body1">
                  Edit Project Name
                </Typography> : <Typography color="primary" sx={{ mb: 1 }} variant="body1">
                  Select a Company
                </Typography> }
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  { isEditProject
                    ? <TextField
                        fullWidth
                        label="Company"
                        InputProps={{
                          readOnly: true,
                        }}
                        sx={{
                          mt: 1,
                          ml: 1,
                        }}
                        value={projectEdit?.companyName}
                    />
                    : <FormControl
                        fullWidth
                        sx={{
                          mt: 1,
                          ml: 1,
                        }}
                    >
                      <InputLabel>
                        Company
                      </InputLabel>
                      <VirtualizedDropdown
                        data={companydata && companydata?.map((company) => ({
                          value: company.companyId,
                          label: company.companyName,
                        }))}
                        value={formData?.companyId}
                        onChange={handleChange}
                        placeholder="No Company Found"
                        renderItem={(item) => (
                          <span>{item.label}</span>
                        )}
                        label="Company"
                        fullWidth
                        isSearchable
                      />
                    </FormControl>}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      placeholder="Enter Project Name"
                      label="ProjectName"
                      margin="dense"
                      variant="outlined"
                      name="projectName"
                      value={projectShow ? projectEdit?.projectName : projectName}
                      onChange={(e) => {
                        setProjectError(commonUtils.sanitizeInput(e.target.value)?.length < 5);
                        onChange(e, projectShow);
                      }}
                      helperText={projectError ? 'Project Name should be greater than 5 characters' : ''}
                      fullWidth
                      error={projectError}
                      ref={focusPoint}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    sx={{
                      mt: 1,
                      ml: 1,
                    }}
                  >
                    <InputLabel>
                      File Type
                    </InputLabel>
                    <Select
                      fullWidth
                      opens={opens}
                      onClose={handleCloses}
                      value={projectShow ? projectEdit?.framework : formData?.framework}
                      label="File Type"
                      name="framework"
                      onChange={(e) => {
                        if (isEditProject) {
                          onChange(e);
                        } else {
                          handleChange(e);
                        }
                      }}
                    >
                      {Object.entries(DashboardEnum).map(([type, name]) => (
                        <MenuItem key={type} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </div>
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        {loading && <CircularProgress style={{ marginLeft: '25px' }} />}
        <Button onClick={handleClose} variant="outlined">Cancel</Button>
        {projectEdit?.projectName ? <Button disabled={projectError} autoFocus color="primary" variant="contained" onClick={handleUpdateForm}>
          Update
        </Button> : <Button autoFocus color="primary" disabled={!(formData?.companyId && formData?.framework) || projectError} variant="contained" onClick={() => { onSubmit(); }}>
          Submit
        </Button>}
      </DialogActions>
    </StyledDialog>
  );
}
ProjectModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleSubmitForm: PropTypes.func.isRequired,
  handleUpdateForm: PropTypes.func.isRequired,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  projectEdit: PropTypes.string.isRequired,
  projectShow: PropTypes.bool.isRequired,
  formData: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isEditProject: PropTypes.bool,
};

ProjectModal.defaultProps = {
  open: PropTypes.bool,
  isEditProject: false,
};
