const getJiraUrl = (values) => {
  const rootUrl = 'https://auth.atlassian.com/authorize';

  const state = encodeURIComponent(
    JSON.stringify({
      companyId: values.companyId,
      projectId: values.projectId,
    }),
  );

  const options = {
    redirect_uri: process.env.REACT_APP_JIRA_REDIRECT_URI,
    client_id: process.env.REACT_APP_JIRA_CLIENT_ID,
    response_type: 'code',
    prompt: 'consent',
    state,
    scope: [
      'read:me',
      'read:account',
      'read:jira-work',
      'manage:jira-project',
      'manage:jira-configuration',
      'read:jira-user',
      'write:jira-work',
      'offline_access',
    ].join(' '),
  };

  const qs = new URLSearchParams(options);
  return `${rootUrl}?${qs.toString()}`;
};

export default getJiraUrl;
