import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControl, Grid, IconButton, InputLabel,

  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteSlackWebHook, getSlackWebHook } from '../../services/integrations';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import companyService from '../../services/company.service';
import reportService from '../../services/report.service';
import { useCommonContext } from '../header/context';
import { UserRole } from '../../utils/constants';
import { tableStyles } from '../header/style';
import VirtualizedDropdown from '../VirtualizedDropdown';

const SlackIntegration = () => {
  const {
    user,
  } = useCommonContext();
  const classes = tableStyles();
  const [webHookUrl, setWebHookUrl] = useState({});
  const [companyDropdown, setCompanyDropdown] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [projectId, setprojectId] = useState(null);
  const [project, setProject] = useState(null);
  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });

  const removeWebHook = async (id) => {
    try {
      await deleteSlackWebHook(id);
      setWebHookUrl('');
      setSnackbar({
        navigateText: '',
        severity: 'success',
        state: true,
        message: 'Deleted Successfully',
      });
    } catch (err) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${err?.data?.message}`,
      });
    }
  };
  useEffect(async () => {
    if (companyId && projectId) {
      try {
        const res = await getSlackWebHook({ companyId, projectId });
        setWebHookUrl(res);
      } catch (err) {
        console.log(err.data?.message);
      }
    }
  }, [companyId, projectId]);

  const companyList = async () => {
    try {
      const res = await companyService.getAll();
      setCompanyId(res[0]?.companyId);
      setprojectId(res[0]?.projects[0]?.projectId);
      setCompanyDropdown(res);
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error.message}`,
      });
    }
  };

  const handleCompanyChange = (e) => {
    const id = e.target.value;
    setCompanyId(id);
    setprojectId('');
  };

  useEffect(() => {
    if (user?.role?.roleName === UserRole.ADMIN) {
      setCompanyId(user?.company?.companyId);
      setprojectId(user?.company?.projectResponses[0]?.projectId);
    }
    companyList();
  }, [user?.role?.roleId]);

  const getProjectName = async () => {
    if (companyId) {
      try {
        const res = await reportService.getProjectById(companyId);
        setProject(res);
      } catch (error) {
        setSnackbar({
          navigateText: '',
          severity: 'error',
          state: true,
          message: `${error.data.message}`,
        });
      }
    }
  };

  useEffect(() => {
    getProjectName();
  }, [user?.role?.roleId, companyId]);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={8} sm={4}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel size="small">
                Company
              </InputLabel>
              <VirtualizedDropdown
                data={companyDropdown && companyDropdown?.map((company) => ({
                  value: company.companyId,
                  label: company.companyName,
                }))}
                isCurved
                isSearchable
                value={companyId}
                onChange={handleCompanyChange}
                placeholder="No Company Found"
                renderItem={(item) => (
                  <span>{item.label}</span>
                )}
                label="Company"
                size="small"
              />
            </FormControl>
          </Grid>
          <Grid item xs={8} sm={4}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel size="small">
                Projects
              </InputLabel>
              <VirtualizedDropdown
                data={project && project?.map((menuitem) => ({
                  value: menuitem?.projectId,
                  label: menuitem?.projectName,
                }))}
                isCurved
                isSearchable
                value={projectId || ''}
                onChange={(e) => { setprojectId(e.target.value); }}
                placeholder="No Project Found"
                renderItem={(item) => (
                  <span>{item.label}</span>
                )}
                label="Projects"
                size="small"
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} mt={4}>
          <Paper className={classes.summaryBox}>
            <Box className={classes.summaryBoxIn}>
              <TableContainer component={Paper} className={classes.tableContainer}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.headerCell}>WebHookUrl</TableCell>
                      <TableCell className={classes.headerCell}>Company</TableCell>
                      <TableCell className={classes.headerCell}>Project</TableCell>
                      <TableCell className={classes.headerCell}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {webHookUrl?.webhookUrl ? (
                      <TableRow key={webHookUrl?.id}>
                        <TableCell sx={{ maxWidth: '40%', wordBreak: 'break-all' }}>{webHookUrl.webhookUrl}</TableCell>
                        <TableCell>{webHookUrl?.company}</TableCell>
                        <TableCell>{webHookUrl?.project}</TableCell>
                        <TableCell>
                          <IconButton color="error" size="small" onClick={() => removeWebHook(webHookUrl.id)}>
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          No Webhook Added.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      {
        snackbar.state
        && (
          <NavigateSnackbar
            snackbarObj={snackbar}
            setSnackbar={setSnackbar}
          />
        )
      }
    </>
  );
};

export default SlackIntegration;
